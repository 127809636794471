<template>
  <div class="top-bar">
    <div class="heading">ACT Interviewer App</div>
    <nav class="nav-links">
        <TextButton to="/" buttonText="Home" class="button" />
      
        <div v-if="isScoring">
          <TextButton buttonText="Scores" @click="emitOpenScoringPanel" />
        </div>
    </nav>
  </div>
</template>

<script>
import TextButton from './TextButton.vue';

export default {
    components: {
        TextButton,
    },
    methods: {
        emitOpenScoringPanel() {
            this.$emit('openScoringPanel');
        }
    },
    computed: {
      isScoring() {
          return this.$route.query.selection === 'score';
      }
    }
};
</script>

<style scoped>
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3986A8;
  padding: 10px;
  width: 100%;
  font-size: 12px;
}

.heading {
  font-size: 24px;
  margin-right: 30px;
}

.nav-links {
  display: flex;
  align-items: center;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0;
    z-index: 1000;
    padding: 20px;
}

.button {
    margin-right: 10px;
}
</style>
