import TaskRecord from '../records/TaskRecord'
import VerbalFluencyTaskRecord from '../records/VerbalFluencyTaskRecord'
import WordPairRecallTaskRecord from '../records/WordPairRecallTaskRecord'
import BostonNamingTaskRecord from '../records/BostonNamingTaskRecord'
import ClockDrawingTaskRecord from '../records/ClockDrawingTaskRecord'
import ConstructionalPraxisRecallTaskRecord from '../records/ConstructionalPraxisRecallTaskRecord'
import ContructionalPraxisTaskRecord from '../records/ContructionalPraxisTaskRecord'
import LogicalMemoryRecallTaskRecord from '../records/LogicalMemoryRecallTaskRecord'
import LogicalMemoryTaskRecord from '../records/LogicalMemoryTaskRecord'
import WaisRTaskRecord from '../records/WaisRTaskRecord'
import WordListDelayedRecallTaskList from '../records/WordListDelayedRecallTaskList'
import WordListTaskRecord from '../records/WordListTaskRecord'

class LogParser {

    constructor() {
        this.tasks = null
        this.linterIsWack = new TaskRecord()
    }

    parse(jsonData) {
        console.log("==========================================")
        this.tasks = []
        const data = jsonData['data']
        console.log("data "+ data)
        this.parseBatteries(data)
    }

    parseBatteries(batteriesJson) {
        const batteries = batteriesJson.map((battery) => { return this.parseBattery(battery) })
        console.log('batteries: '+batteries)
    }

    parseBattery(batteryJson) {
        console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< '+batteryJson['Battery'])
        batteryJson['Summary'].forEach((stage, index) => { this.parseStage(batteryJson, stage, index) })

        return {}
    }

    parseStage(batteryJson, stageJson, stageIndex) {
        let stageLabel = this.stageLabel(stageJson)
        console.log('battery: '+batteryJson['Battery']+' stage ('+stageIndex+'): '+stageJson['StageType']+' >> '+stageLabel)
        
        this.parseVerbalFluency(batteryJson, stageJson, stageIndex)
        this.parseWordPairRecall(batteryJson, stageJson, stageIndex)
        this.parseLogicalMemory(batteryJson, stageJson, stageIndex)
        this.parseClockDrawing(batteryJson, stageJson, stageIndex)
        this.parseBostonNaming(batteryJson, stageJson, stageIndex)
        this.parseWAIS_R(batteryJson, stageJson, stageIndex)
        this.parseWordList(batteryJson, stageJson, stageIndex)
        this.parseConstructionalPraxis(batteryJson, stageJson, stageIndex)
        this.parseWordListDelayedRecall(batteryJson, stageJson, stageIndex)
        this.parseConstructionalPraxisRecall(batteryJson, stageJson, stageIndex)
        this.parseLogicalMemoryRecall(batteryJson, stageJson, stageIndex)
    }

    /*
        Verbal Fluency Animals:
        data[1]['Summary][]
            ['StageType'] == 'Recording' && ['Parameters']['StageLabel'] == 'Verbal Fluency - Animals'
            ['Summary']['RecordingFilename']
        
        Verbal Fluency Supermarket:
        data[1]['Summary][]
            ['StageType'] == 'Recording' && ['Parameters']['StageLabel'] == 'Verbal Fluency - Supermarket'
            ['Summary']['RecordingFilename']

        Verbal Fluency Letter F:
        data[1]['Summary][]
            ['StageType'] == 'Recording' && ['Parameters']['StageLabel'] == 'Verbal Fluency - Letter F'
            ['Summary']['RecordingFilename']
    */
    parseVerbalFluency(batteryJson, stageJson, stageIndex) {

        if (this.stageType(stageJson) != 'Recording') return
        let label = this.stageLabel(stageJson)
        if (label.indexOf('Verbal Fluency') == -1) return
        
        // console.log(`    >>>> VERBAL FLUENCY!!`)

        let task = this.addTask(new VerbalFluencyTaskRecord(), label, batteryJson, stageJson, stageIndex)
        task.parseLogExtra(stageJson)
    }

    /*
        Logical Memory Anna:
        data[1]['Summary][]
            ['StageType'] == 'VerbalQuestion' && ['Parameters']['AudioType']['StoryName'] == 'Anna Thompson'
            ['RecordingName']

        Logical Memory Robert:
        data[1]['Summary][]
            ['StageType'] == 'VerbalQuestion' && ['Parameters']['AudioType']['StoryName'] == 'Robert Miller'
            ['RecordingName']
    */
    parseLogicalMemory(task, stageLabel, batteryJson, stageJson, stageIndex) {
        if (this.stageType(stageJson) != 'VerbalQuestion') return
        if (this.storyName(stageJson) == '') return
        
        // console.log(`    >>>> LOGICAL MEMORY: ${this.storyName(stageJson)}`)

        this.addTask(new LogicalMemoryTaskRecord(), '', batteryJson, stageJson, stageIndex)
    }

    /*
        data[1]['Summary][]
            ['StageType'] == 'WordPairRecall'
            ['Parameters']['StageLabel'] -> which set
            ['Recordings'][]
                ['Type'] == 'Record'
                ['Name'] -> audio file of recording
                ['ExpectedWord'] -> word that is correct
    */
    parseWordPairRecall(batteryJson, stageJson, stageIndex) {
        if (this.stageType(stageJson) != 'WordPairRecall') return

        // console.log(`    >>>> WORD PAIR RECALL`)

        let task = this.addTask(new WordPairRecallTaskRecord(), this.stageLabel(stageJson), batteryJson, stageJson, stageIndex)
        task.parseLogExtra(stageJson)
    }

    /*
        Clock Drawing:
        data[1]['Summary][]
            ['StageType'] == 'DrawingTask' && ['Parameters']['StageLabel'] == 'Clock Drawing'
            ['Drawings'][]['Filename'] -> Clock drawing pictures
    */
    parseClockDrawing(batteryJson, stageJson, stageIndex) {
        if (this.stageType(stageJson) != 'DrawingTask') return
        if (this.stageLabel(stageJson) != 'Clock Drawing') return

        console.log(`    >>>> CLOCK DRAWING`)
        console.log(`         stage json: ${JSON.stringify(stageJson)}`)

        let task = this.addTask(new ClockDrawingTaskRecord(), '', batteryJson, stageJson, stageIndex)
        task.parseLogExtra(stageJson)
    }

    /*
    Boston Naming:
    data[1]['Summary][]
        ['StageType'] == 'ImagesRecall'
        ['Recordings'][]  
            ['Image'] -> correct answer
            ['RecordingName'] -> audio of boston naming
    */
    parseBostonNaming(batteryJson, stageJson, stageIndex) {
        if (this.stageType(stageJson) != 'ImagesRecall') return
        // if (this.stageLabel(stageJson) != 'ImagesRecall') return

        // console.log(`    >>>> BOSTON NAMING`)

        let task = this.addTask(new BostonNamingTaskRecord(), '', batteryJson, stageJson, stageIndex)
        task.parseLogExtra(stageJson)
    }

    /*
        WAIS-R:
        data[1]['Summary][]
            ['StageType'] == 'VerbalQuestion' && ['Parameters']['AudioType']['Type'] == 'WaisRQuestion'
            ['RecordingName'] -> audio for Wais of question type
            ['Parameters']['StageLabel'] -> which set of questions
    */
    parseWAIS_R(batteryJson, stageJson, stageIndex) {
        if (this.stageType(stageJson) != 'VerbalQuestion') return
        if (this.audioType(stageJson) != 'WaisRQuestion') return

        // console.log(`    >>>> WAIS R`)
        
        let task = this.addTask(new WaisRTaskRecord(), '', batteryJson, stageJson, stageIndex)
        task.parseLogExtra(stageJson)
    }

    /*
        Word List: - should be able to distiguish between training and recall, but not 100% on best way yet
        data[1]['Summary][]
            ['StageType'] == 'Recording' && ['Parameters']['StageLabel'] =~ /Recall \d+/
            ['Summary']['RecordingFilename'] -> audio for word list recall
    */
    parseWordList(batteryJson, stageJson, stageIndex) {
        if (this.stageType(stageJson) != 'Recording') return
        let label = this.stageLabel(stageJson)
        if (label == '') return
        if (label.indexOf('Recall') == -1) return
        if (label == 'Recall') return                                       // this is a WORD LIST DELAYED RECALL
        if (label == 'Recall Story A' || label == 'Recall Story B') return  // this is a LOGICAL MEMORY RECALL

        // console.log(`    >>>> WORD LIST`)

        let task = this.addTask(new WordListTaskRecord(), label, batteryJson, stageJson, stageIndex)
        task.parseLogExtra(stageJson)
    }

    /*
        Constructional Praxis:
        data[1]['Summary][]
            ['StageType'] == 'DrawingTask' && ['Parameters']['StageLabel'] == 'Circle Copy'
            ['Drawings'][]['Filename'] -> Circle copy Drawings
            ['StageType'] == 'DrawingTask' && ['Parameters']['StageLabel'] == 'Diamond Copy'
            ['Drawings'][]['Filename'] -> Diamond copy Drawings
            ['StageType'] == 'DrawingTask' && ['Parameters']['StageLabel'] == 'Rectangles'
            ['Drawings'][]['Filename'] -> Rectangles copy Drawings
            ['StageType'] == 'DrawingTask' && ['Parameters']['StageLabel'] == 'Cube Copy'
            ['Drawings'][]['Filename'] -> Cube copy Drawings
    */
    parseConstructionalPraxis(batteryJson, stageJson, stageIndex) {
        if (this.stageType(stageJson) != 'DrawingTask') return
        let label = this.stageLabel(stageJson)
        if (label != 'Circle Copy' && label != 'Diamond Copy' && label != 'Rectangles' && label != 'Rectangles Copy' && label != 'Cube Copy') return
        
        // console.log(`    >>>> CONSTRUCTIONAL PRAXIS`)

        let task = this.addTask(new ContructionalPraxisTaskRecord(), label, batteryJson, stageJson, stageIndex)
        task.parseLogExtra(stageJson)
    }
    
    /*
        Word List Delayed Recall:
        data[1]['Summary][]
            ['StageType'] == 'Recording' && ['Parameters']['StageLabel'] == 'Recall'
            ['Summary']['RecordingFilename'] -> audio for delayed recall
    */
    parseWordListDelayedRecall(batteryJson, stageJson, stageIndex) {
        if (this.stageType(stageJson) != 'Recording') return
        if (this.stageLabel(stageJson) != 'Recall') return

        // console.log(`    >>>> WORD LIST DELAYED RECALL`)

        let task = this.addTask(new WordListDelayedRecallTaskList(), this.stageLabel(stageJson), batteryJson, stageJson, stageIndex)
        task.parseLogExtra(stageJson)
    }

    /*
        Constructional Praxis Recall:
        data[1]['Summary][]
            ['StageType'] == 'DrawingTask' && ['Parameters']['StageLabel'] == 'Drawing Freehand Task'
            ['Drawings'][]['Filename'] -> drawn pics
    */
    parseConstructionalPraxisRecall(batteryJson, stageJson, stageIndex) {
        if (this.stageType(stageJson) != 'DrawingTask') return
        if (this.stageLabel(stageJson) != 'Drawing Freehand Task') return

        // console.log(`    >>>> CONSTRUCTIONAL PRAXIS RECALL`)

        let task = this.addTask(new ConstructionalPraxisRecallTaskRecord(), this.stageLabel(stageJson), batteryJson, stageJson, stageIndex)
        task.parseLogExtra(stageJson)
    }

    /*
        Logical Memory Recall:
        data[1]['Summary][]
            ['StageType'] == 'Recording' && ['Parameters']['StageLabel'] == 'Recall Story A' || 'Recall Story B'
            ['Summary']['RecordingFilename'] -> audio for story recall
    */
    parseLogicalMemoryRecall(batteryJson, stageJson, stageIndex) {
        if (this.stageType(stageJson) != 'Recording') return
        let label = this.stageLabel(stageJson)
        if (label != 'Recall Story A' && label != 'Recall Story B') return

        // console.log(`    >>>> LOGICAL MEMORY RECALL`)

        let task = this.addTask(new LogicalMemoryRecallTaskRecord(), label, batteryJson, stageJson, stageIndex)
        task.parseLogExtra(stageJson)
    }

    //=========================================================================

    addTask(task, stageLabel, batteryJson, stageJson, stageIndex) {
        task.battery = batteryJson['Battery']
        task.stageType = this.stageType(stageJson)
        task.stageLabel = stageLabel
        task.index = stageIndex
        task.json = stageJson
        this.json = stageJson
        this.tasks.push(task)

        return task
    }

    stageType(json) { return json && json['StageType'] ? json['StageType'] : '' }

    stageLabel(json) { return (json && json['Parameters'] && json['Parameters']['StageLabel']) ? json['Parameters']['StageLabel'] : '' }

    storyName(json) { 
        return (json && json['Parameters'] && json['Parameters']['AudioType'] && json['Parameters']['AudioType']['StoryName']) ? json['Parameters']['AudioType']['StoryName'] : ''; 
    }

    // ['Parameters']['AudioType']['Type']
    audioType(json) { 
        return (json && json['Parameters'] && json['Parameters']['AudioType'] && json['Parameters']['AudioType']['Type']) ? json['Parameters']['AudioType']['Type'] : ''; 
    }

    //--
}

export default LogParser;
