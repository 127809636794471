import TaskRecord from './TaskRecord';

class BostonNamingTaskRecord extends TaskRecord {

    constructor() {
        super()
        this.taskType = 'BostonNaming'
        this.recordings = null
    }

    /*
    Boston Naming:
    data[1]['Summary][]
        ['StageType'] == 'ImagesRecall'
        ['Recordings'][]  
            ['Image'] -> correct answer
            ['RecordingName'] -> audio of boston naming
    */
    parseLogExtra(logJson) {
        this.recordings = logJson['Recordings']
    }

    //--
}

export default BostonNamingTaskRecord;