<template>
  <BaseLayout :heading="'Verbal Fluency - Letter F - Interview'" />

  <div class="instructions">
    <div class="line">I am going to say a letter of the alphabet. When I say begin, I want you to <b>Press the continue button</b> and tell me as many words as you can think of with that letter. You will have 60 seconds before I tell you to stop. None of the words can be names of people, or places, or numbers. For example, if I gave you the letter T, you could say take, toy, tooth, and so forth, but you should not say Tom because that is a person’s name, you should not say Texas because that is the name of a place, and you should not say twelve because that is a number. Also, do not give me the same word with different endings. For example, if you say take, you should not also say takes and taking. Do you have any questions?</div>
    <div class="line">The letter is F. As in A-B-C-D-E…F.  Ready? <b>Press continue and begin.</b></div>
    <div class="line">(Wait for the time’s up message after one minute. If there has been a 15 second pause with no response, say “keep going, the letter F”)</div>
    <div class="line">That is fine. <b>Press the continue button.</b></div>
  </div>

  <VerbalFluencyInterview
    :jsonData="jsonData"
    @update:enteredWord="updateEnteredWord"
    @update:selectedAnswer="updateSelectedAnswer"
  />

  <NotesSection 
    :administeringNotes="jsonData ? jsonData.administeringNotes : ''" 
    :scoringNotes="jsonData ? jsonData.scoringNotes : ''" 
    ref="notesSection" />

  <TextButton buttonText="Submit" :dark="true" type="submit" @click="submitForm" />

  <NotificationPopup 
    :message="notificationMessage" 
    :visible="notificationVisible"
  />

  <ConfirmationPopup
    :visible="showConfirmPopup"
    @confirm="handleConfirmLeave"
    @noSave="handleNoSaveLeave"
    @cancel="showConfirmPopup = false"
  />
</template>

<script>
import BaseLayout from '../BaseLayout.vue';
import VerbalFluencyInterview from '@/components/Pages/VerbalFluencyInterview.vue';
import TaskController from '../../controllers/TaskController';
import ServerCodeModel from '../../models/ServerCodeModel';
import NotificationPopup from '@/components/NotificationPopup.vue';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
import NotesSection from '@/components/NotesSection.vue';
import TextButton from '@/components/TextButton.vue';

export default {
  components: {
    BaseLayout,
    VerbalFluencyInterview,
    NotificationPopup,
    ConfirmationPopup,
    NotesSection,
    TextButton
  },
  data() {
    return {
      controller: null,
      jsonData: null,
      notificationMessage: '',
      notificationVisible: false,
      hasUnsavedChanges: false,
      showConfirmPopup: false,
      nextRoute: null,
      forceNavigate: false
    };
  },
  computed: {
    isAdministering() {
        return this.$route.query.selection === 'administer';
    }
  },
  async mounted() {
    const serverCodeModel = new ServerCodeModel();
    serverCodeModel.logType = 'InterviewerVerbalFluencyLetterFLog';

    this.controller = new TaskController(serverCodeModel.logType, 'verbalFluencyLetterFData', serverCodeModel);

    await this.loadData();
  },
  methods: {
    async loadData() {
      await this.controller.loadData();
      this.jsonData = this.controller.model.data;
      this.hasUnsavedChanges = false;
    },
    updateEnteredWord(index, value) {
      this.jsonData.entries[index].enteredWord = value;
      this.hasUnsavedChanges = true;
    },
    updateSelectedAnswer(index, value) {
      this.jsonData.entries[index].selectedAnswer = value;
      this.hasUnsavedChanges = true;
    },
    async submitForm() {
      this.notificationMessage = 'Submitting...';
      this.notificationVisible = true;

      if (this.jsonData.timeCreated === '') {
        this.jsonData.timeCreated = new Date().toUTCString();
      }
      this.jsonData.timeLastModified = new Date().toUTCString();

      const notesSection = this.$refs.notesSection;
      const administeringNotes = notesSection.getAdministeringNotes();
      const scoringNotes = notesSection.getScoringNotes();

      if (this.jsonData) {
        this.jsonData.administeringNotes = administeringNotes || '';
        this.jsonData.scoringNotes = scoringNotes || '';
      }

      try {
        await this.controller.submitForm();
        this.notificationMessage = 'Submitted Successfully!';
        this.hasUnsavedChanges = false;
      } catch (error) {
        this.notificationMessage = 'Submitting failed. Please try again.';
        console.error('Error submitting form:', error);
      }
      finally {
        this.resetNotification();
      }
    },
    resetNotification() {
      setTimeout(() => {
        this.notificationVisible = false;
      }, 3000);
    },
    async handleConfirmLeave() {
      this.showConfirmPopup = false;
      this.hasUnsavedChanges = false;
      await this.submitForm();
      this.$router.push(this.nextRoute);
    },
    handleNoSaveLeave() {
      this.showConfirmPopup = false;
      this.forceNavigate = true;
      this.hasUnsavedChanges = false;
      this.$router.push(this.nextRoute);
    }
  },
  beforeRouteLeave(to, from, next) {
    const notesSection = this.$refs.notesSection;
    const currentAdministeringNotes = notesSection.getAdministeringNotes();
    const currentScoringNotes = notesSection.getScoringNotes();

    if (this.forceNavigate) {
      this.forceNavigate = false;
      this.hasUnsavedChanges = false;
      next();
      return;
    }

    if ( 
      (this.isAdministering && currentAdministeringNotes !== this.jsonData.administeringNotes) || 
      (!this.isAdministering && currentScoringNotes !== this.jsonData.scoringNotes) 
    ) {
      this.hasUnsavedChanges = true;
    }

    if (this.hasUnsavedChanges) {
      this.showConfirmPopup = true;
      this.nextRoute = to.fullPath;
      next(false);
    } else {
      this.controller.model.saveData(this.jsonData);
      next();
    }
  }
};
</script>

<style scoped>
.instructions {
  font-size: 18px;
}
.line {
  margin-bottom: 20px;
}
</style>