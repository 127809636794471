class TaskRecord {

    constructor() {
        this.taskType = ''
        this.battery = ''
        this.stageType = ''
        this.stageLabel = ''
        this.stageIndex = ''
        this.fileName = ''
        this.json = null
    }

    parseLogExtra(logJson) { console.log(logJson)}
    title() {
        return `${this.taskType} >> ${this.battery} : ${this.stageType} : ${this.stageLabel}`
    }

    //--
}

export default TaskRecord;