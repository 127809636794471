import TaskModel from '../models/TaskModel.js';
//import { exportToJsonFile } from '../utils/export.js';

class TaskController {
    constructor(logType, storageKey) {
        this.model = new TaskModel(logType, storageKey);
    }

    async submitForm() {
        if (!this.model) {
            throw new Error('Error initializing model');
        }
        
        console.log('this is submitting task');
        await this.model.submitData(this.model.data);
        await this.model.saveData(this.model.data);
        //await this.model.saveData(this.model.data);
        //await exportToJsonFile(this.model.data, this.model.storageKey);
    }

    async loadData() {
        await this.model.fetchData();
    }
}

export default TaskController;