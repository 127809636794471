const getSubjectData = (subjectId) => {
    try {
        const allData = JSON.parse(localStorage.getItem('subjectData') || '{}');
        return allData[subjectId] || {};
    } catch (error) {
        console.error('Error parsing subject data:', error);
        return {};
    }
};

const setSubjectData = (subjectId, key, data) => {
    if (!subjectId) {
        console.error('No subject ID provided for storage');
        return;
    }
    if (data === undefined) {
        console.warn(`Attempting to save undefined data for key: ${key}`);
        return;
    }

    try {
        const allData = JSON.parse(localStorage.getItem('subjectData') || '{}');
        if (!allData[subjectId]) {
            allData[subjectId] = {};
        }
        allData[subjectId][key] = data;
        localStorage.setItem('subjectData', JSON.stringify(allData));
    } catch (error) {
        console.error('Error saving subject data:', error);
    }
};

export { getSubjectData, setSubjectData };
