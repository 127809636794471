<template>
  <div class="content-box">
    <div class="prompt">
      <TextButton
        buttonText="Back"
        :dark="true"
        @click="$emit('close')"
        class="back-button"
      />
      
      <div class="next-question">
        <p class="selection-title">Export Data</p>
        
        <div class="subject-list">
          <div class="subject-header">
            <div class="checkbox-column"></div>
            <div class="id-column">Subject ID</div>
            <div class="time-column">Last Export Time</div>
          </div>
          <div v-for="subjectId in subjectIds" :key="subjectId" class="subject-item">
            <div class="checkbox-column">
              <input 
                type="checkbox" 
                :value="subjectId" 
                v-model="selectedSubjects"
              >
            </div>
            <div class="id-column">{{ subjectId }}</div>
            <div class="time-column">{{ getLastExportTime(subjectId) }}</div>
          </div>
        </div>

        <div class="select-all-container">
          <TextButton
            buttonText="Select All"
            :dark="true"
            @click="selectAll"
            class="select-all-button"
          />
          <TextButton
            buttonText="Deselect All"
            :dark="true"
            @click="deselectAll"
            class="select-all-button"
          />
        </div>

        <TextButton
          buttonText="Export Selected"
          :dark="true"
          @click="handleExport"
          :disabled="!selectedSubjects.length"
          class="export-button"
        />
        <div class="selection-count" v-if="selectedSubjects.length">
          {{ selectedSubjects.length }} subject{{ selectedSubjects.length !== 1 ? 's' : '' }} selected
        </div>
      </div>
    </div>

    <ExportResultsModal
      :show="showResults"
      :results="exportResults"
      @close="handleModalClose"
    />
  </div>
</template>

<script>
import TextButton from './TextButton.vue'
import ExportResultsModal from './ExportResultsModal.vue'
import { exportSelectedSubjects } from '../utils/export'

export default {
  name: 'ExportSubjectSelector',
  components: {
    TextButton,
    ExportResultsModal
  },
  data() {
    return {
      selectedSubjects: [],
      subjectIds: [],
      exportTimes: {},
      showResults: false,
      exportResults: {
        exported: [],
        skipped: []
      }
    }
  },
  mounted() {
    this.loadSubjectIds();
    this.loadExportTimes();
  },
  methods: {
    loadSubjectIds() {
      const allData = JSON.parse(localStorage.getItem('subjectData') || '{}');
      this.subjectIds = Object.keys(allData);
    },
    loadExportTimes() {
      this.exportTimes = JSON.parse(localStorage.getItem('exportTimes') || '{}');
    },
    getLastExportTime(subjectId) {
      return this.exportTimes[subjectId] || 'Never';
    },
    async handleExport() {
      try {
        const result = await exportSelectedSubjects(this.selectedSubjects);
        
        if (result) {
          this.exportResults = result;
          this.showResults = true;

          if (result.exported.length > 0) {
            this.selectedSubjects = [];
          }
        }
      } catch (error) {
        console.error('Export error:', error);
      }
    },
    handleModalClose() {
      this.showResults = false;
      this.exportResults = { exported: [], skipped: [] };
      this.$emit('close');
    },
    selectAll() {
      this.selectedSubjects = [...this.subjectIds];
    },
    deselectAll() {
      this.selectedSubjects = [];
    }
  }
}
</script>

<style scoped>
.content-box {
  background-color: #F4F4F4;
  padding: 40px;
  border-radius: 6px;
  border: 1px solid #a0a0a0;
  max-width: 600px;
  height: 450px;
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
}

.prompt {
  text-align: center;
}

.selection-title {
  font-size: 30px;
  color: #3986A8;
  margin-bottom: 30px;
}

.next-question {
  margin: 0 auto;
  position: relative;
}

.back-button {
  position: absolute;
  top: 30px;
  left: 30px;
}

.subject-list {
  max-height: 200px;
  overflow-y: auto;
  margin: 20px 0;
  text-align: left;
  border: 2px solid #3986A8;
  border-radius: 8px;
  background-color: white;
}

.subject-header {
  display: flex;
  padding: 12px 16px;
  background-color: #f5f5f5;
  border-bottom: 2px solid #3986A8;
  font-weight: bold;
}

.subject-item {
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid #eee;
  align-items: center;
}

.subject-item:last-child {
  border-bottom: none;
}

.checkbox-column {
  width: 40px;
  display: flex;
  align-items: center;
}

.id-column {
  flex: 1;
  text-align: left;
}

.time-column {
  width: 200px;
  text-align: right;
}

.subject-checkbox input[type="checkbox"] {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.export-button {
  width: 100%;
  margin-top: 20px;
}

.selection-count {
  margin-top: 10px;
  font-size: 14px;
  color: #666;
}

.subject-list::-webkit-scrollbar {
  width: 8px;
}

.subject-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 0 0 4px 0;
}

.subject-list::-webkit-scrollbar-thumb {
  background: #3986A8;
  border-radius: 4px;
}

.subject-list::-webkit-scrollbar-thumb:hover {
  background: #237091;
}

.select-all-container {
  margin: 20px 0;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.select-all-button {
  font-size: 14px;
  padding: 5px 10px;
  width: auto !important;
}

.export-message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.export-message.info {
  background-color: #e3f2fd;
  color: #1976d2;
  border: 1px solid #bbdefb;
}

.export-message.error {
  background-color: #ffebee;
  color: #c62828;
  border: 1px solid #ffcdd2;
}

.export-details {
  margin-top: 10px;
  font-size: 13px;
}

.export-details ul {
  margin: 5px 0;
  padding-left: 20px;
}

.export-details li {
  margin: 2px 0;
}
</style>
