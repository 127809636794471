<template>
    <div class="score-row">
        <span class="label">{{ label }}</span>
        <div class="score-box">{{ score }}</div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        score: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style scoped>
.score-row {
    display: grid;
    grid-template-columns: auto min-content;
    gap: 5px;
    align-items: center;
    margin-bottom: 10px;
}

.label {
    text-align: left;
    white-space: nowrap;
}

.score-box {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 4px;
    text-align: center;
    min-width: 50px;
}
</style>
