<template>
    <div>
        <component :is='comp()' :controller='controller' :task='task'/>
    </div>
</template>
  
<script>
  /* eslint-disable vue/no-dupe-keys */
import { defineComponent, toRefs } from 'vue'
import TaskGeneric from './Tasks/TaskGeneric'
import TaskVerbalFluency from './Tasks/TaskVerbalFluency'
import TaskWordPairRecall from './Tasks/TaskWordPairRecall'
import TaskClockDrawing from './Tasks/TaskClockDrawing'
import TaskBostonNaming from './Tasks/TaskBostonNaming'
import TaskWAISR from './Tasks/TaskWAISR'
import TaskWordList from './Tasks/TaskWordList'
import TaskConstructionalPraxis from './Tasks/TaskConstructionalPraxis'
import TaskLogicalMemory from './Tasks/TaskLogicalMemory'

export default defineComponent({
    props: {
        controller: {
            type: Object,
            required: true
        },
        task: {
            type: Object
        }
    },
    components: {
        TaskGeneric,
        TaskVerbalFluency,
        TaskWordPairRecall,
        TaskClockDrawing,
        TaskWAISR,
        TaskWordList,
        TaskConstructionalPraxis,
        TaskLogicalMemory
    },
    
    setup(props) {
        const { task } = toRefs(props);
        return { task };
    },

    methods: {
        comp() {
            if ('VerbalFluency' == this.task.taskType) return TaskVerbalFluency
            else if ('WordPairRecall' == this.task.taskType) return TaskWordPairRecall
            else if ('ClockDrawing' == this.task.taskType) return TaskClockDrawing
            else if ('BostonNaming' == this.task.taskType) return TaskBostonNaming
            else if ('WAIS_R' == this.task.taskType) return TaskWAISR
            else if ('WordListTask' == this.task.taskType) return TaskWordList
            else if ('WordListDelayedRecall' == this.task.taskType) return TaskWordList
            else if ('ConstructionalPraxis' == this.task.taskType) return TaskConstructionalPraxis
            else if ('ConstructionalPraxisRecall' == this.task.taskType) return TaskConstructionalPraxis
            else if ('LogicalMemory' == this.task.taskType) return TaskLogicalMemory
            else if ('LogicalMemoryRecall' == this.task.taskType) return TaskLogicalMemory
            
            return TaskGeneric
        }
    },
});

</script>

<style scoped>
  .input-field input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
</style>