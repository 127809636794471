<template>

    <div>
        <strong>{{ task.taskType }}</strong>
        <p>
            <strong>Stage Label</strong>: {{  task.stageLabel }}<br>
            <strong>File Name</strong>: {{  task.fileName }}
        </p>
        <LogAudioPlayer :label='label' :audioSource='task.fileName'/>
    <TaskConfig :task='task'/>
    </div>

</template>

<script>
import TaskConfig from '@/tmp_log_scoring/components/TaskConfig.vue'
import LogAudioPlayer from '@/tmp_log_scoring/components/LogAudioPlayer.vue'
export default {
    props: ['controller', 'task'],
    components: {
        TaskConfig,
        LogAudioPlayer
    },
    data() {
        return {
            label: ''
        }
    }
};
</script>

<style>
    

</style>