<template>
    <div v-if="tasks">
      <label for="dropdown">Select Task:</label><br>
      <select @change="onSelect" id="dropdown">
        <option v-for="task in tasks" :key="task" :value="task">
          {{ task.title() }}
        </option>
      </select>
      <p></p>
    </div>
</template>
  
<script>
  /* eslint-disable vue/no-dupe-keys */
import { defineComponent, toRefs } from 'vue';
export default defineComponent({
    props: {
        controller: {
            type: Object,
            required: true
        },
        tasks: {
            type: Object
        }
    },
    
    setup(props) {
        const { tasks } = toRefs(props);
        return { tasks };
    },

    methods: {
        onSelect(event) { this.controller.selectTask(event.target.selectedIndex) }
    }
});

</script>

<style scoped>
  .input-field input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
</style>