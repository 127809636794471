<template>
    <div class="container">
        <div v-for="(item, index) in scores" :key="index" class="item">
            <div class="score-label">{{ item.label }}</div>
            <div>{{ item.score }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScoreLabelSection',
    props: {
        scores: {
            type: Array,
            required: true,
            default: () => []
        }
    }
}
</script>

<style scoped>
.container {
    display: flex;
    gap: 2vw;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.score-label {
    font-weight: bold;
}
</style>
