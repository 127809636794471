<template>
  <div>
    <CASIPreQuestions :preQuestions="jsonData.prequestions" @input-changed="handleInputChanged" />

    <div class="heading">Questions</div>
    <div v-for="(section, sectionIndex) in allQuestions" :key="'section' + sectionIndex" class="section">
      <div v-if="section.instructions" class="instructions">
        <span v-for="(instruction, index) in section.instructions" :key="'instruction' + sectionIndex + '-' + index">
          {{ instruction }}
        </span>
      </div>
      <CASIQuestionsSection :questions="section.questions" @score-added="handleScoreAdded" @input-changed="handleInputChanged" />
    </div>

    <div v-if="shouldShowSecondBdayQuestion">
      <div v-for="(section, sectionIndex) in secondBdayQuestion" :key="'additional-section' + sectionIndex" class="section">
        <CASIQuestionsSection :questions="section.questions" @score-added="handleScoreAdded" @input-changed="handleInputChanged" />
      </div>
    </div>

    <CASIPostQuestions :postQuestions="jsonData.postquestions" @input-changed="handleInputChanged" />
  </div>
</template>

<script>
import CASIPreQuestions from './CASIPrequestions.vue';
import CASIPostQuestions from './CASIPostquestions.vue';
import CASIQuestionsSection from './CASIQuestionsSection.vue';
import casiState from '@/store/casiState';

export default {
  props: {
    jsonData: {
      type: Object,
      required: true,
    },
  },
  components: { 
    CASIPreQuestions, CASIPostQuestions, CASIQuestionsSection 
  },
  methods: {
    handleScoreAdded(scoreLabel, value) {
      this.$emit('score-added', scoreLabel, value);
    },
    handleInputChanged() {
      this.$emit('input-changed');
    }
  },
  computed: {
    allQuestions() {
      return this.jsonData.sections.filter(section => 
        section.questions.every(question => !question.conditional)
      );
    },
    secondBdayQuestion() {
      return this.jsonData.sections.filter(section => 
        section.questions.some(question => question.conditional === true)
      );
    },
    shouldShowSecondBdayQuestion() {
      return !casiState.isMatchingDay || !casiState.isMatchingMonth || !casiState.isMatchingYear;
    }
  }
};
</script>

<style scoped>
.heading {
  font-size: 28px;
    margin-bottom: 24px;
    color: #237091;
}
</style>
