import localClockDrawingData from '../data/clockDrawing.json';
import localCasiData from '../data/casi.json';
import localHearingTestData from '../data/hearingTests.json';
import localTrailMakingAData from '../data/trailMakingA.json';
import localTrailMakingBData from '../data/trailMakingB.json';
import localVerbalFluencyLetterFData from '../data/verbalFluencyLetterF.json';
import localVerbalFluencySupermarketData from '../data/verbalFluencySupermarket.json';
import { setSubjectData, getSubjectData } from '../utils/storage';
import { isOnline } from '@/utils/network';

class ServerCodeModel {
    static dataKey() { return 'serverCodeData' }
    static instance = null;

    constructor(data = {}) {
        if (ServerCodeModel.instance) {
            return ServerCodeModel.instance;
        }
        ServerCodeModel.instance = this;
        
        this.data = data
        this.resources = null
        this.casiEmpty
        this.serverCode = "actpilotb"
        this.baseURL = "https://upzdy4gcd3.execute-api.us-east-2.amazonaws.com"
        this.logBaseURL = "https://braingamecenter.s3.us-east-2.amazonaws.com"
        this.key = "3Hz6KpZXBb2aFY57wCo137fm9OWmmyOo9D7TJCsx"
        this.game = "PART"
    
        this.error = null
        
        this.useServerCode = false
        this.subjectId = ''
        this.organization = 'braingamecenter'
        this.study = "ACT_BIENNIAL_PILOT_2024_08"
        this.logGameName = "ACTInterviewer"
        this.batteryNumber = '001'
        this.sessionNumber = '001'
        this.timeStamp = '24_08_27_00_00_02'
        this.logType = 'InterviewerCASILog'

        this.saveResult = {ok:true, error:"some message"}
        this.loadForAdministerResult = {ok:true, error:"some message"}
        this.loadForScoringResult = {ok:true, error:"some message"}

    }

    //=========================================================================
    //
    //=========================================================================

    async loadForAdminister(subjectId) {
        this.saveSubjectId(subjectId);
        
        const logsExist = await this.loadLog();
        if (logsExist) {
            this.loadForAdministerResult = {ok: true, error: ""};
            return;
        }

        var ok = true;
        if (this.useServerCode) {
            ok = await this.loadServerCode();
            if (!ok) {
                this.loadForAdministerResult = {ok:false, error:`Could not load server code`};
                return;
            }
        }
        
        this.saveDataToLocalStorage('casiData', localCasiData);
        this.saveDataToLocalStorage('hearingTestData', localHearingTestData);
        this.saveDataToLocalStorage('clockDrawingData', localClockDrawingData);
        this.saveDataToLocalStorage('trailMakingAData', localTrailMakingAData);
        this.saveDataToLocalStorage('trailMakingBData', localTrailMakingBData);
        this.saveDataToLocalStorage('verbalFluencyLetterFData', localVerbalFluencyLetterFData);
        this.saveDataToLocalStorage('verbalFluencySupermarketData', localVerbalFluencySupermarketData);

        this.loadForAdministerResult = {ok: true, error: ""};
    }

    //=========================================================================
    //
    //=========================================================================

    async loadForScoring(subjectId) {
        this.saveSubjectId(subjectId);

        var ok = true;
        if (this.useServerCode) {
            ok = await this.loadServerCode();
            if (!ok) {
                this.loadForScoringResult = {ok: false, error: `Could not load server code`};
                return;
            }
        }
        
        ok = await this.loadLog();
        if (!ok) {
            this.loadForScoringResult = {ok:false, error:`Logs not found for: ${subjectId}`};
            return;
        }
        
        this.loadForScoringResult = {ok:true, error:""};
    }

    async loadLog() {
        if (!isOnline()) {
            const subjectId = localStorage.getItem('subjectId');
            const existingData = getSubjectData(subjectId);
            
            if (Object.keys(existingData).length > 0) {
                console.log('Offline mode: using existing local data');
                return true;
            }
        }

        const logTypes = ['InterviewerCASILog', 
                          'InterviewerHearingTestsLog', 
                          'InterviewerClockDrawingLog', 
                          'InterviewerTrailMakingALog', 
                          'InterviewerTrailMakingBLog',
                          'InterviewerVerbalFluencyLetterFLog',
                          'InterviewerVerbalFluencySupermarketLog'];
    
        try {
            const logPromises = logTypes.map(async (logType) => {
                const url = `${this.logBaseURL}/${this.study}/${this.logGameName}/${this.createLogFileName(logType)}`;
                
                const response = await fetch(url);
                if (!response.ok) {
                    console.error(`${logType} log not found`);
                    return false;
                }
    
                const data = await response.json();
                switch (logType) {
                    case 'InterviewerCASILog':
                        this.saveDataToLocalStorage('casiData', data);
                        break;
                    case 'InterviewerHearingTestsLog':
                        this.saveDataToLocalStorage('hearingTestData', data);
                        break;
                    case 'InterviewerClockDrawingLog':
                        this.saveDataToLocalStorage('clockDrawingData', data);
                        break;
                    case 'InterviewerTrailMakingALog':
                        this.saveDataToLocalStorage('trailMakingAData', data);
                        break;
                    case 'InterviewerTrailMakingBLog':
                        this.saveDataToLocalStorage('trailMakingBData', data);
                        break;
                    case 'InterviewerVerbalFluencyLetterFLog':
                        this.saveDataToLocalStorage('verbalFluencyLetterFData', data);
                        break;
                    case 'InterviewerVerbalFluencySupermarketLog':
                        this.saveDataToLocalStorage('verbalFluencySupermarketData', data);
                        break;
                }
    
                return true;
            });
    
            const results = await Promise.all(logPromises);
            return results.some(result => result);    
        } catch (error) {
            console.error('Error loading logs:', error);
            return false;
        }
    }
    
    //=========================================================================
    //
    //=========================================================================

    saveServerCodeToLocalStorage(data) {
        localStorage.setItem('serverCodeData', data)
    }

    saveDataToLocalStorage(key, data) {
        const subjectId = localStorage.getItem('subjectId');
        if (data === undefined) {
            console.warn(`Attempting to save undefined data for key: ${key}`);
            return;
        }
        setSubjectData(subjectId, key, data);
    }
    
    saveSubjectId(subjectId) {
        if (!subjectId || subjectId == '') subjectId = 'defaultuser'
        this.subjectId = subjectId
        localStorage.setItem('subjectId', subjectId)
    }

    async loadServerCode(){
        
        const params = new URLSearchParams({
            game: this.game,
            serverCode: this.serverCode
        })

        const options = {
            method: 'GET',
            headers: {
                'x-api-key': this.key,
                'Content-Type': 'application/json'
            }
        }

        const url = `${this.baseURL}/Prod/StudyCondition?${params.toString()}`;

        try {
            const response = await fetch(url, options);
            if (!response.ok) { return false }
            this.data = await response.json();
            this.saveServerCodeToLocalStorage(this.data)

        } catch (error) { return false }

        return true
    }

    async saveData(data) {
    
        this.saveResult = {ok:true, error:""}

        const url = `${this.baseURL}/Prod/UploadData`;
        const formData = {
            organization: this.organization,
            study: this.study,
            game: this.logGameName,
            logType: this.logType,
            file_name: this.createLogFileName(this.logType),  
            content: JSON.stringify(data)
        }

        console.log('Sending data to server:');
        console.log('URL:', url);
        console.log('Form Data:', JSON.stringify(formData, null, 2)); 
        
        try {
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': this.key,
              },
              body: JSON.stringify(formData)
            });
    
            if (!response.ok) {
                this.saveResult.ok = false
                this.saveResult.error = response.status
                return false
            }
    
            const data = await response.json();
            console.log('Server Response:', data);

        
        } catch (error) {
            this.saveResult.ok = false
            this.saveResult.error = error
            return false
        }

        return true
    }

    clearData() {
        const subjectId = localStorage.getItem('subjectId');
        const existingData = getSubjectData(subjectId);
        
        const defaultData = {
            casiData: existingData.casiData || localCasiData,
            hearingTestData: existingData.hearingTestData || localHearingTestData,
            clockDrawingData: existingData.clockDrawingData || localClockDrawingData,
            trailMakingAData: existingData.trailMakingAData || localTrailMakingAData,
            trailMakingBData: existingData.trailMakingBData || localTrailMakingBData,
            verbalFluencyLetterFData: existingData.verbalFluencyLetterFData || localVerbalFluencyLetterFData,
            verbalFluencySupermarketData: existingData.verbalFluencySupermarketData || localVerbalFluencySupermarketData
        };

        Object.entries(defaultData).forEach(([key, value]) => {
            if (!existingData[key]) {
                setSubjectData(subjectId, key, value);
            }
        });
    }
    
    // createLogFileName() {
    //     //"kace_001_001_InterviewerLog_24_08_27_00_00_02.json",
    //     var sid = localStorage.getItem('subjectId')
    //     return `${sid}_${this.sessionNumber}_${this.batteryNumber}_${this.logType}_${this.timeStamp}.json`
    // }

    createLogFileName(logType) {
        var sid = localStorage.getItem('subjectId');
        return `${sid}_${this.sessionNumber}_${this.batteryNumber}_${logType}_${this.timeStamp}.json`;
    }
    
    //=========================================================================
    //
    //=========================================================================

    getBatteryFilesList() {
        return ["ACTBiennialIntroduction.json",
        "ACTNI3ClockDrawing.json",
        "ACTNI3TrailMaking.json",
        "ACTNI3VerbalFluency2.json",
        "AudioTest.json",
        "CASIInstructions.json",
        "CRM2tracks.json",
        "MAMATDSI082124.json"]
    }

    async getResourcesList()
    {
        const options = {
            method: 'POST',
            headers: {
                'x-api-key': this.key,
                'Content-Type': 'application/json'
            },
            body: {
                game: this.game,
                resource: 'Battery',
                serverCode: this.serverCode,
                resList: this.getBatteryFilesList()
            }
        }

        const url = `${this.baseURL}/Prod/GetResource`;

        try {
            const response = await fetch(url, options);
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            this.resources = await response.json();
            localStorage.setItem('resources', this.resources)

          } catch (error) {
            this.error = error.message
            console.error('There was an error!', error)

          } finally {
            this.loading = false
        }
    }

}
  
export default ServerCodeModel;