import TaskRecord from './TaskRecord';

class WordListTaskRecord extends TaskRecord {

    constructor() {
        super()
        this.taskType = 'WordListTask'
    }

    parseLogExtra(logJson) {
        if (!logJson) return
        if (!logJson['Summary']) return
        this.fileName = logJson['Summary']['RecordingFilename']
    }

    //--
}

export default WordListTaskRecord;