import LogParser from './parsers/LogParser'
import Logs from '@/tmp_log_scoring/model/Logs'

class LogModel {

    constructor(data = {}) {
        this.data = data
        this.logParser = null
        this.loadedJson = null
        this.tasks = null
    }
  
    async loadRemote(logFileName) {
        try {
            const response = await fetch(Logs.logsURLBase + logFileName)
            console.log(`loading: ${Logs.logsURLBase + logFileName}`)
            if (!response.ok) {
                console.log(`HTTP error! Status: ${response.status}`)
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            this.loadedJson = await response.json()
        }
        catch (error) {
            console.error('Error fetching JSON:', error);
            // Handle the error or return a fallback value
            return null;
        }
    }

    async loadLog(logFileName) {
        await this.loadRemote(logFileName)
        this.parseData(this.loadedJson)
    }

    parseData(jsonData) {
        this.logParser = new LogParser()
        this.logParser.parse(jsonData)
        this.tasks = this.logParser.tasks
        console.log(`tasks: ${this.tasks}`)

        this.tasks.forEach((task) => { console.log(`${task.title()}`) });
    }

    //--
}
  
export default LogModel;