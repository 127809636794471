<template>
    <div class="note-text-disabled">
        <label for="note-disabled" class="note-label">Administering Notes:</label>
        <textarea id="note-disabled" class="note-input" disabled :value="content"></textarea>
    </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: 'No administering notes available.'
    }
  }
};
</script>

<style scoped>
.note-text-disabled {
    padding: 20px 0;
}

.note-label {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
}

.note-input {
    width: 100%;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
    padding: 10px;
    font-size: 16px;
    resize: vertical;
    color: #666;
    cursor: not-allowed;
}
</style>
