<template>
    <div class="notes-section">
        <label for="notes" class="notes-label">{{ label }}</label>
        <textarea id="notes" class="notes-input" placeholder="Add notes here" :value="content"></textarea>
    </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Notes:'
    },
    content: {
      type: String,
      default: 'Notes:'
    }
  }
}
</script>

<style scoped>
.notes-section {
    padding: 20px 0;
}

.notes-label {
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
}

.notes-input {
    width: 100%;
    height: 150px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    background-color: #fff;
    padding: 10px;
    font-size: 16px;
}

.notes-input::placeholder {
    color: #999;
}
</style>
