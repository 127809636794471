import { getAllData, deleteData } from './db';
import ServerCodeModel from '../models/ServerCodeModel';

const serverCodeModel = new ServerCodeModel();

const MAX_RETRIES = 2;

export const syncData = async () => {
  const dataToSync = await getAllData();
  console.log('Data to sync:', dataToSync);

  for (const data of dataToSync) {
    const uniqueData = JSON.parse(JSON.stringify(data.data));
    const taskLogType = uniqueData.logType;
    let attempts = 0;
    let success = false;

    while (attempts < MAX_RETRIES && !success) {
      attempts++;
      console.log(`Attempting to upload task with ID: ${data.id}, Attempt: ${attempts}, logType: ${taskLogType}`);
      success = await serverCodeModel.saveData(uniqueData);

      if (success) {
        await deleteData(data.id);
        console.log(`Successfully uploaded and deleted task with ID: ${data.id}`);
      } else {
        console.error(`Failed to upload task with ID: ${data.id}, Attempt: ${attempts}, Error: ${serverCodeModel.saveResult.error}`);
        if (attempts < MAX_RETRIES) {
          // Added in this logic bc sometimes the internet re-connects before the POST request is able to get sent and it fails, but then the second time it will pass
          console.log('Retrying in 2 seconds...');
          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      }
    }
  }
};

const onOnline = () => {
  //syncData();
};

export { onOnline };
