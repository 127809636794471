<template>
  <div v-if="show" class="modal-overlay">
    <div class="modal-content">
      <h2 class="modal-title">Export Results</h2>
      
      <div class="results-content">
        <div v-if="results.exported.length">
          <h3>Successfully Exported:</h3>
          <ul>
            <li v-for="id in results.exported" :key="id">• {{ id }}</li>
          </ul>
        </div>
        
        <div v-if="results.skipped.length">
          <h3>Skipped (No Data):</h3>
          <ul>
            <li v-for="id in results.skipped" :key="id">• {{ id }}</li>
          </ul>
        </div>
      </div>

      <div class="modal-footer">
        <TextButton
          buttonText="Close"
          :dark="true"
          @click="$emit('close')"
          class="close-button"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextButton from './TextButton.vue'

export default {
  name: 'ExportResultsModal',
  components: {
    TextButton
  },
  props: {
    show: Boolean,
    results: {
      type: Object,
      default: () => ({ exported: [], skipped: [] })
    }
  }
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  min-width: 300px;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-title {
  color: #3986A8;
  margin: 0 0 20px 0;
  text-align: center;
  font-weight: normal;
}

.results-content {
  margin: 20px 0;
  max-height: 300px;
  overflow-y: auto;
}

.results-content h3 {
  color: #666;
  font-size: 16px;
  margin: 15px 0 10px 0;
}

.results-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.results-content li {
  padding: 5px 0;
  color: #333;
  margin-left: 20px;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.close-button {
  margin: 0 auto;
}
</style>
