<template>

    <div>
        <strong>{{ task.taskType }}</strong>
        <p>
            <strong>Stage Label</strong>: {{  task.stageLabel }}<br>
        </p>
        <ul>
            <li v-for="(recording, index) in task.recordings" :key="index">
                <LogAudioPlayer :label='recording.Image' :audioSource='recording.RecordingName'/>
                {{ recording }}
            </li>
        </ul>
        <TaskConfig v-if="showTaskConfig" :task='task'/>
    </div>

</template>


<script>
import TaskConfig from '@/tmp_log_scoring/components/TaskConfig.vue'
import LogAudioPlayer from '@/tmp_log_scoring/components/LogAudioPlayer.vue'
export default {
    props: ['controller', 'task'],
    components: {
        TaskConfig,
        LogAudioPlayer
    },
    data() {
        return {
            showTaskConfig: false
        }
    }
};
</script>

<style>
    

</style>