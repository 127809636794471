<template>
  <div>
    <p>{{ label }}</p>
      <audio ref="audioElement" :src="fullAudioUrl" controls></audio>
  </div>
</template>

<script>
/* eslint-disable vue/no-dupe-keys */
import { ref, toRefs, computed, onBeforeUnmount } from 'vue';

import Logs from '@/tmp_log_scoring/model/Logs'

export default {
  props: {
    label: {
        type: String
    },
    audioSource: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { audioSource } = toRefs(props);
    const fullAudioUrl = computed(() => `${Logs.logsURLBase}${audioSource.value}`)
    const audioElement = ref(null);

    onBeforeUnmount(() => {
      if (audioElement.value) {
        audioElement.value.pause()
      }
    });

    return {
      fullAudioUrl,
      audioElement
    };
  }
};
</script>