<template>
    <div class="instructions">
        <h3 class="header">Setup</h3>
        <ol>
            <li>Set the device volume to max if this has not been done already. If the volume is not set to max before they run the task, you will see a warning.</li>
            <li>Plug the headphones into the tablet.</li>
        </ol>

        <h3 class="header">Administer Speech Reception & DSI</h3>
        <p>To minimize participant burden, the instructions for both hearing tasks will be reviewed simultaneously, reducing the need for repeated removal of headphones between tasks. However, if this approach proves challenging due to the participant's memory, it is perfectly acceptable to adjust and review the instructions between each task.</p>
        
        <ol>
            <li>Review Speech Reception Instructions with the participant as follows:</li>
            <ol type="a">
                <li>You will complete two hearing tasks.</li>
                <li>In the first task, you will hear a person call the name Charlie followed by the directions to go to a specific combination of color and number. Your job is to press the button on the grid that matches the color and number you heard.</li>
                <li>To measure the quietest sentence you can understand, the voice will gradually lower in volume, eventually becoming too soft to hear clearly. This is a normal part of the process, if you cannot hear what the voice is saying just guess. You will first have a few practice rounds, then you will go on to the full task. Do you have any questions about the first task?</li>
            </ol>

            <li>Review DSI instructions with the participant as follows:</li>
            <ol type="a">
                <li>“The second hearing task has two parts. During the first part of this task, you will see 6 sentences on the screen. For each trial, you will hear one of these sentences spoken into one ear. After the sentence has finished, your job is to select the one that you heard. Do you have any questions about the first part?”</li>
                <li>“During the first part of this task, you will see 6 sentences on the screen. During each trial, you will hear two of these sentences spoken, one into each ear. After you finish listening to the sentences, your job is to select the two sentences that you heard. Do you have any questions?”</li>
            </ol>

            <li>If there are no questions, instruct the participant as follows:</li>
            <ol type="a">
                <li>“Please put on the headphones. The cord should be on your left side. Once the headphones are on, you may begin the task by pressing continue on the tablet.”</li>
            </ol>
        </ol>
    </div>
</template>

<style scoped>
.instructions {
    font-size: 18px;
}

.header {
    text-decoration: underline;
}
</style>