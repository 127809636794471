<template>
  <div class="input-group">
    <label v-if="!['circle', 'heavier', 'square'].includes(input.name)" :for="input.name" class="input-label" @click.prevent>
      {{ input.label }}
    </label>

    <div class="label-container">
      <div v-for="(label, index) in input.labelArray" :key="index">
        {{ index + 1 }}. {{ label }}
      </div>
    </div>

    <div class="inputs">
      <CASITextInput
        v-if="input.type === 'text'"
        :input="input"
        :modelValue="modelValue"
        @input-changed="$emit('input-changed')"
      />
      <CASIChoiceInput
        v-if="input.type === 'prompt'"
        :input="input"
        :modelValue="modelValue"
        @input-changed="$emit('input-changed')"
      />
      <CASIScoreInput
        v-if="input.scores && !['highestCheckbox', 'totalCheckbox'].includes(input.type)"
        :input="input"
        :modelValue="modelValue"
        @score-added="handleScoreAdded"
      />
      <CASIHighestScoreCheckboxInput
        v-if="input.type === 'highestCheckbox'"
        :input="input"
        :modelValue="modelValue"
        @score-added="handleScoreAdded"
      />
      <CASITotalScoreCheckboxInput
        v-if="input.type === 'totalCheckbox'"
        :input="input"
        :modelValue="modelValue"
        @score-added="handleScoreAdded"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import CASITextInput from './CASITextInput.vue';
import CASIChoiceInput from './CASIChoiceInput.vue';
import CASIScoreInput from './CASIScoreInput.vue';
import CASIHighestScoreCheckboxInput from './CASIHighestScoreCheckboxInput.vue';
import CASITotalScoreCheckboxInput from './CASITotalScoreCheckboxInput.vue';

export default defineComponent({
  components: { CASITextInput, CASIChoiceInput, CASIScoreInput, CASIHighestScoreCheckboxInput, CASITotalScoreCheckboxInput },
  props: {
    input: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },
  setup(props, { emit }) {
    function handleScoreAdded(scoreLabel, value) {
      emit('score-added', scoreLabel, value);
    }

    return {
      handleScoreAdded
    };
  }
});
</script>

<style scoped>
.input-label {
  display: block;
  font-size: 14px;
  margin-bottom: 3px;
}
.inputs {
  display: flex;
}
.label-container {
  font-size: 14px;
}
.input-group {
  margin-bottom: 20px;
}
</style>