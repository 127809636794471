import LogModel from '../model/LogModel';

class LogController {

    constructor() {
        this.model = this.newModel()

        this.isSelectingLogFile = true
        this.hasLog = false
        this.hasTask = false
        this.task = null
    }
    
    
    newModel() { 
        return new LogModel() 
    }

    loadLogNamed(logName){
        this.clearSelected()
        this.load(logName)
    }

    async load(logName) {
        await this.model.loadLog(logName);
        this.hasLog = true
    }

    selectTask(taskIndex) {
        this.hasTask = true
        this.task = this.model.tasks[taskIndex]
        console.log(`selected task: ${this.task} : ${this.task.title()}, ${this.task.taskType}`)
    }

    clearSelected() {
        this.hasLog = false
        this.hasTask = false
    }

    async submitForm() {
        if (!this.model) {
            throw new Error('Error initializing model');
        }
        await this.model.saveData();
    }
}

export default LogController;
