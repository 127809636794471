<template>
    <div>
        <p><strong>{{ task.taskType }}</strong></p>
        <ul>
            <li v-for="(drawing, index) in task.drawings" :key="index">
                <LogImageViewer :label='label' :imageSource='drawing.Filename'/>
                {{ drawing }}
            </li>
        </ul>
        <TaskConfig v-if="showTaskConfig" :task='task'/>
    </div>
</template>

<script>
import TaskConfig from '@/tmp_log_scoring/components/TaskConfig.vue'
import LogImageViewer from '@/tmp_log_scoring/components/LogImageViewer.vue'
export default {
    props: ['controller', 'task'],
    components: {
        TaskConfig,
        LogImageViewer
    },
    data() {
        return {
            showTaskConfig: false,
            label: '',
        }
    }
};
</script>

<style>
    

</style>