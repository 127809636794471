import ServerCodeModel from './ServerCodeModel.js';
import { getSubjectData, setSubjectData } from '../utils/storage';
import { isOnline } from '@/utils/network.js';

class CasiModel {
    constructor(data = {}) {
        this.data = data;
        this.serverCodeModel = new ServerCodeModel();
        this.serverCodeModel.logType = 'InterviewerCASILog';
    }

    async fetchData() {
        const subjectId = localStorage.getItem('subjectId');
        const subjectData = getSubjectData(subjectId);

        if (!isOnline() && subjectData['casiData']) {
            this.data = subjectData['casiData'];
            console.log('Using local data:', this.data);
            return;
        }

        this.data = subjectData['casiData'] || {};
        console.log('Fetched data:', this.data);
    }

    async saveData(data) {
        const subjectId = localStorage.getItem('subjectId');
        if (!data || !Array.isArray(data.sections)) {
            console.error('Attempting to save invalid CASI data structure');
            return;
        }
        setSubjectData(subjectId, 'casiData', data);
        this.data = data;
    }

    async submitData(data) {
        if (!data || !Array.isArray(data.sections)) {
            console.error('Attempting to submit invalid CASI data structure');
            return;
        }

        if(isOnline()) {
            await this.serverCodeModel.saveData(data);
            if (this.serverCodeModel.saveResult.ok) {
                console.log('Data saved successfully on server');
                return;
            }
            console.error('Failed to save data:', this.serverCodeModel.saveResult.error);
        }

        await this.saveData(data);
        console.log('Data saved locally');
    }
}

export default CasiModel;