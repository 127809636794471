import ServerCodeModel from './ServerCodeModel.js';
import { isOnline } from '../utils/network';
import { getSubjectData, setSubjectData } from '../utils/storage';

class TaskModel {
    constructor(logType, storageKey) {
        this.logType = logType;
        this.storageKey = storageKey;
        this.data = {};
        this.serverCodeModel = new ServerCodeModel();
        this.serverCodeModel.logType = logType;
    }
    
    async fetchData() {
        const subjectId = localStorage.getItem('subjectId');
        const subjectData = getSubjectData(subjectId);
        
        if (!isOnline() && subjectData[this.storageKey]) {
            this.data = subjectData[this.storageKey];
            console.log('Using local data:', this.data);
            return;
        }

        this.data = subjectData[this.storageKey] || {};
        console.log('Fetched data:', this.data);
    }

    async saveData(data) {
        console.log('Saving data:', data);
        const subjectId = localStorage.getItem('subjectId');
        setSubjectData(subjectId, this.storageKey, data);
        this.data = data;
    }

    async submitData(data) {
        console.log('Submitting data:', data);
        const uniqueData = JSON.parse(JSON.stringify(data));
        uniqueData.logType = this.logType;

        if (isOnline()) {
            console.log('Using logType:', this.logType);
            await this.serverCodeModel.saveData(uniqueData);
            if (this.serverCodeModel.saveResult.ok) {
                console.log('Data saved successfully on server');
                return;
            }
            console.error('Failed to save data:', this.serverCodeModel.saveResult.error);
        }
        
        await this.saveData(uniqueData);
        console.log('Data saved locally');
    }
}

export default TaskModel;