import JSZip from 'jszip';
import { getSubjectData } from './storage';

export const exportToJsonFile = async (data, taskName) => {
    const json = JSON.stringify(data, null, 2);
    const formattedDateTime = new Date().toISOString().replace(/T/, '_').replace(/:/g, '-').slice(0, 19);
    var subjectId = localStorage.getItem('subjectId');
    const filename = `${subjectId}_${taskName}_${formattedDateTime}.json`;
    return { filename, json };
};

export const updateExportTime = (subjectIds) => {
    const exportTimes = JSON.parse(localStorage.getItem('exportTimes') || '{}');
    const currentTime = new Date().toLocaleString();
    
    subjectIds.forEach(subjectId => {
        exportTimes[subjectId] = currentTime;
    });
    
    localStorage.setItem('exportTimes', JSON.stringify(exportTimes));
};

export const exportSelectedSubjects = async (selectedSubjectIds) => {
    const zip = new JSZip();
    const formattedDateTime = new Date().toISOString().replace(/T/, '_').replace(/:/g, '-').slice(0, 19);
    const exportFolderName = `export_${formattedDateTime}`;
    const successfullyExportedSubjects = [];
    const skippedSubjects = [];

    const exportFolder = zip.folder(exportFolderName);

    for (const subjectId of selectedSubjectIds) {
        const subjectData = getSubjectData(subjectId);
        const subjectFolder = exportFolder.folder(subjectId);
        let hasFiles = false;
        
        const metadata = {
            subjectId: subjectId,
            exportDateTime: new Date().toISOString(),
            completedTasks: []
        };
        
        for (const [taskName, taskData] of Object.entries(subjectData)) {
            if (!taskData?.timeCreated) {
                continue;
            }

            const filename = `${subjectId}_${taskName}_${formattedDateTime}.json`;
            const json = JSON.stringify(taskData, null, 2);
            subjectFolder.file(filename, json);
            hasFiles = true;

            metadata.completedTasks.push({
                taskName,
                timeCreated: taskData.timeCreated,
                timeLastModified: taskData.timeLastModified || 'N/A'
            });
        }

        const metadataJson = JSON.stringify(metadata, null, 2);
        subjectFolder.file(`${subjectId}_metadata.json`, metadataJson);

        if (!hasFiles) {
            exportFolder.remove(subjectId);
            skippedSubjects.push(subjectId);
        } else {
            successfullyExportedSubjects.push(subjectId);
        }
    }

    const blob = await zip.generateAsync({ type: 'blob' });
    const zipFilename = `export_${formattedDateTime}.zip`;

    try {
        let saved = false;
        
        if (window.showSaveFilePicker) {
            try {
                const fileHandle = await window.showSaveFilePicker({
                    suggestedName: zipFilename,
                    types: [{
                        description: 'ZIP file',
                        accept: {
                            'application/zip': ['.zip']
                        }
                    }]
                });

                const writable = await fileHandle.createWritable();
                await writable.write(blob);
                await writable.close();
                saved = true;
            } catch (err) {
                if (err.name === 'AbortError') {
                    return null;
                }
                throw err;
            }
        } else {
            // Fallback method (some browsers dont support the modern method so this just goes to downloads folder)
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = zipFilename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            saved = true;
        }

        if (saved) {
            if (successfullyExportedSubjects.length > 0) {
                updateExportTime(successfullyExportedSubjects);
            }
            return {
                exported: successfullyExportedSubjects,
                skipped: skippedSubjects
            };
        }
        return null;

    } catch (error) {
        console.error('Export error:', error);
        throw error;
    }
};
