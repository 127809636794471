<template>
  <div :class="['scoring-panel', { open: isOpen }]">
    <div class="content">
      <button class="close-btn" @click="closePage">X</button>

      <h1>Scoring Sheet</h1>

      <ScoreSection taskName="Clock Drawing">
        <ScoreRow label="Time (s):" :score=0 />
      </ScoreSection>

      <ScoreSection taskName="CASI">
        <ScoreRow label="Attention Score:" :score="casiATT" />
        <ScoreRow label="Mental Manipulation/Concentration Score:" :score="casiMMC" />
        <ScoreRow label="Orientation Score:" :score="casiORI" />
        <ScoreRow label="Long-term Memory Score:" :score="casiLTM" />
        <ScoreRow label="Short-term Memory Score:" :score="casiSTM" />
        <ScoreRow label="Language Score:" :score="casiLANG" />
        <ScoreRow label="Visual Construction Score:" :score="casiDRAW" />
        <ScoreRow label="List-generating Fluency Score:" :score="casiFLU" />
        <ScoreRow label="Abstraction and Judgment Score:" :score="casiATAJ" />
        <ScoreRow label="Total Score:" :score="totalScore" class="total-score"/>
      </ScoreSection>

      <ScoreSection taskName="Verbal Fluency, Letter F">
        <ScoreRow label="Total Correct:" :score=0 />
        <ScoreRow label="Total Incorrect:" :score=0 />
        <ScoreRow label="Total Repetitions:" :score=0 />
      </ScoreSection>

      <ScoreSection taskName="Verbal Fluency, Supermarket">
        <ScoreRow label="Total Correct:" :score=0 />
        <ScoreRow label="Total Incorrect:" :score=0 />
        <ScoreRow label="Total Repetitions:" :score=0 />
      </ScoreSection>

      <ScoreSection taskName="Trail Making A">
        <ScoreRow label="Time (s):" :score=0 />
        <ScoreRow label="Errors:" :score=0 />
      </ScoreSection>

      <ScoreSection taskName="Trail Making B">
        <ScoreRow label="Time (s):" :score=0 />
        <ScoreRow label="Errors:" :score=0 />
      </ScoreSection>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ScoreSection from './ScoreSection.vue';
import ScoreRow from './ScoreRow.vue';
import CASIController from '../controllers/CASIController';

export default {
  components: {
    ScoreSection,
    ScoreRow
  },
  data() {
    return {
      controller: new CASIController(),
      jsonData: null,
      totalScore: null,
      casiATT: null,
      casiMMC: null,
      casiORI: null,
      casiLTM: null,
      casiSTM: null,
      casiLANG: null,
      casiDRAW: null,
      casiFLU: null,
      casiATAJ: null
    };
  },
  async mounted() {
    await this.loadData();
    this.calculateScores();
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    ...['totalScore', 'casiATT', 'casiMMC', 'casiORI', 'casiLTM', 'casiSTM', 'casiLANG', 'casiDRAW', 'casiFLU', 'casiATAJ'].reduce((acc, key) => {
      acc[key] = { type: Number, default: 0 };
      return acc;
    }, {})
  },
  methods: {
    closePage() {
      this.$emit('close');
    },
    async loadData() {
      await this.controller.loadData();
    },
    calculateScores() {
      this.controller.calculateScores();
      this.updateScores();
    },
    updateScores() {
      this.totalScore = this.controller.totalScore;
      this.casiATT = this.controller.casiATT;
      this.casiMMC = this.controller.casiMMC;
      this.casiORI = this.controller.casiORI;
      this.casiLTM = this.controller.casiLTM;
      this.casiSTM = this.controller.casiSTM;
      this.casiLANG = this.controller.casiLANG;
      this.casiDRAW = this.controller.casiDRAW;
      this.casiFLU = this.controller.casiFLU;
      this.casiATAJ = this.controller.casiATAJ;
    },
  },
};
</script>

<style scoped>
.scoring-panel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 95%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  overflow-y: auto;
}

.scoring-panel.open {
  transform: translateX(0);
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: white;
  color: #317391;
  border: 2px solid #317391;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  padding: 5px;
  z-index: 1100;
}

.close-btn:hover {
  background-color: #e0e0e0;
  transform: scale(1.1);
}

.close-btn:focus {
  outline: none;
}

.content {
  padding: 20px;
  overflow-y: auto;
  height: calc(100% - 40px);
}

.total-score {
  font-weight: bold;
}
</style>
