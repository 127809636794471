import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { setupNetworkListeners } from './utils/network';
import { onOnline } from './utils/sync';

import '@fortawesome/fontawesome-free/css/all.css';
import './assets/global.css';
import './registerServiceWorker'

const app = createApp(App);

app.use(router);

setupNetworkListeners(onOnline, () => {
  console.log('You are offline');
});

app.mount('#app');
