<template>
    <BaseLayout :heading="'End'" />

    <div class="line">Thank you very much for taking part in this Pilot Study.</div>
</template>

<script>
import BaseLayout from './BaseLayout.vue';

export default {
    components: {
        BaseLayout
    }
};
</script>

<style scoped>
.line {
    font-size: 20px;
}
</style>