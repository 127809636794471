import TaskRecord from './TaskRecord';

class WordPairRecallTaskRecord extends TaskRecord {

    constructor() {
        super()
        this.taskType = 'WordPairRecall'
        this.recordings = null
    }

    /*
        data[1]['Summary][]
            ['StageType'] == 'WordPairRecall'
            ['Parameters']['StageLabel'] -> which set
            ['Recordings'][]
                ['Type'] == 'Record'
                ['Name'] -> audio file of recording
                ['ExpectedWord'] -> word that is correct
    */
    parseLogExtra(logJson) {
        this.recordings = logJson['Recordings'].filter(recording => recording.Type == 'Record')
    }

    //--
}

export default WordPairRecallTaskRecord;