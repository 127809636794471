<template>
    <div class="notes-section">
        <label for="notes" class="notes-label" @click.prevent>Notes:</label>
        <input id="notes" class="notes-input" placeholder="Type here" v-model="notes" @input="emitNotes" />
    </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: 'Notes:'
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      notes: this.value
    };
  },
  methods: {
    emitNotes() {
      this.$emit('update:notes', this.notes);
    }
  },
  watch: {
    value(newValue) {
      this.notes = newValue;
    }
  }
}
</script>

<style scoped>
.notes-section {
    padding: 20px 0;
}

.notes-label {
    display: block;
    margin-bottom: 10px;
}

.notes-input {
    width: 350px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    padding: 10px;
    font-size: 16px;
}

.notes-input::placeholder {
  opacity: .5;
  font-size: 14px;
}
</style>
