<template>
    <div v-if="visible" class="notification-popup" >
        {{ message }}
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: ''
        },
        visible: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style>
.notification-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 80px;
    border-radius: 10px;
    color: #000;
    border: 4px solid #3986A8;
    background-color: #fff;
    z-index: 1000;
    text-align: center;
    font-size: 24px;
    max-width: 80%;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
</style>
