  <template>
    <div>
      <p>{{ label }}</p>
      <img ref="imageElement" :src="fullImgeURL" alt=""/>
    </div>
  </template>
  
  <script>
  /* eslint-disable vue/no-dupe-keys */
  import { ref, toRefs, computed } from 'vue';
  
  import Logs from '@/tmp_log_scoring/model/Logs'
  
  export default {
    props: {
      label: {
          type: String
      },
      imageSource: {
        type: String,
        required: true
      }
    },
    setup(props) {
      const { imageSource } = toRefs(props);
      const fullImgeURL = computed(() => `${Logs.logsURLBase}${imageSource.value}`)
      const imageElement = ref(null);
  
      return {
        fullImgeURL,
        imageElement
      };
    }
  };
  </script>