<template>
    <div v-if="isScoring">
        <NotesEnabled ref="scoringNotes" :content="scoringNotes" label="Scoring Notes:" />
        <NotesDisabled ref="administeringNotes" :content="administeringNotes" />
    </div>
    <div v-else>
        <NotesEnabled ref="administeringNotes" :content="administeringNotes" label="Administering Notes:" />
    </div>
</template>
  
<script>
import NotesEnabled from './NotesEnabled.vue';
import NotesDisabled from './NotesDisabled.vue';
  
export default {
    components: {
        NotesEnabled,
        NotesDisabled
  },
  props: {
      administeringNotes: {
          type: String,
          default: ''
      },
      scoringNotes: {
          type: String,
          default: ''
      }
  },
  computed: {
    isScoring() {
        return this.$route.query.selection === 'score';
    }
  },
  methods: {
    getAdministeringNotes() {
        const administeringComponent = this.$refs.administeringNotes;
        return administeringComponent ? administeringComponent.notes || administeringComponent.$el.querySelector('textarea').value : '';
    },

    getScoringNotes() {
        const scoringComponent = this.$refs.scoringNotes;
        return scoringComponent ? scoringComponent.notes || scoringComponent.$el.querySelector('textarea').value : '';
    }
  },
  mounted() {
    if (this.administeringNotes) {
        this.$refs.administeringNotes.content = this.administeringNotes;
    }
    if (this.isScoring && this.scoringNotes) {
        this.$refs.scoringNotes.content = this.scoringNotes;
    }
  }
};
</script>