<template>
  <div id="app" class="app">
    <router-view></router-view>
  </div>
</template>


<script>
export default {
  name: 'App',
  mounted() {
    if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
      sessionStorage.setItem('refreshed', 'true');
    }
  },
};
</script>

<style>
.app {
  max-width: 1200px;
  margin: auto;
}
</style>