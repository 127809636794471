<template>
    <BaseLayout :heading="'Introduction'" />

    <div class="intro">
        <div class="first-line">Thank you for volunteering for this pilot study to help us learn more about digital testing and how we can incorporate it into the ACT Study. Please review the consent form and if you do not have any questions, I will have you sign and date it. (Have them sign 2 copies)</div>
        <div>Do you see where the green arrow is pointing at the bottom right of your screen? This is called the continue button. Press it now to see how it moves to the next page.</div>
    </div>
</template>
  
<script>
import BaseLayout from './BaseLayout.vue';
  
export default {
    components: {
        BaseLayout
    }
};
</script>

<style scoped>
.intro {
    font-size: 18px;
}
.first-line {
    margin-bottom: 25px;
}
</style>