<template>
  <div v-if="logs">
    <label for="dropdown">Select Log:</label><br>
    <select v-model="selectedOption" id="dropdown" @change="onSelect">
      <option v-for="log in logs" :key="log" :value="log">
        {{ log }}
      </option>
    </select>
    <p></p>
  </div>
</template>

<script>
import Logs from '@/tmp_log_scoring/model/Logs.js';

export default {
  props: [
    'controller'
  ],
  data() {
    return {
      logs: null,
      selectedOption: '',
    }
  },
  async mounted() {
    this.loadLogsList();
  },
  methods: {
    async loadLogsList() {
      const logsListProvider = new Logs()
      await logsListProvider.loadFilesList()
      this.logs = logsListProvider.filesList['logs']
    },
    onSelect() { 
      this.controller.loadLogNamed(this.selectedOption)
    },
    onClickLoad(){
      this.controller.loadLogNamed(this.selectedOption)
    }
  }
}
</script>

<style scoped>
.input-field input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

</style>
