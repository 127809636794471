<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :to="to"
    :class="['square-button', { 'dark': dark, 'disabled': disabled }]"
    :disabled="disabled"
    :type="type"
  >
    <span class="button-text">{{ displayText }}</span>
  </component>
</template>

<script>
import { isElectron } from '../utils/environment';

export default {
    name: 'TextButton',
    props: {
        buttonText: {
            type: String,
            required: true
        },
        dark: {
            type: Boolean,
            default: true
        },
        to: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'button'
        }
    },
    computed: {
        displayText() {
            if (this.buttonText.toLowerCase() === 'submit' && isElectron()) {
                return 'Save';
            }
            return this.buttonText;
        }
    }
};
</script>

<style scoped>
.square-button {
    height: 40px;
    padding: 0 20px;
    background-color: white;
    border: 2px solid #3986A8;
    color: #3986A8;
    font-size: 1rem;
    font-weight: bold;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    box-sizing: border-box;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.square-button.dark {
    background-color: #3986A8;
    color: white;
}

.square-button.disabled {
    background-color: #d3d3d3;
    color: #a9a9a9;
    border-color: #a9a9a9;
    cursor: not-allowed;
}

.button-text {
    display: inline-flex;
    align-items: center;
    line-height: 1;
}
</style>
