<template>
    <NoteText text="You can make notes on the form below" />
    <div class="note">C = Correct, I = Incorrect, R = Repeat, Q = Query</div>

    <div v-if="jsonData" class="text-input-grid">
        <div v-for="(item, index) in jsonData.entries" :key="item.entryId" class="input-row">
        <span class="input-number">{{ index + 1 }}.</span>
        <TextInputWithToggle 
            :text="item.enteredWord"
            :selectedOption="item.selectedAnswer"
            @update:text="updateEnteredWord(index, $event)"
            @update:selectedOption="updateSelectedAnswer(index, $event)"
        />
        </div>
    </div>
</template>

<script>
import TextInputWithToggle from '../TextInputWithToggle.vue';
import NoteText from '../NoteText.vue';

export default {
    components: {
        TextInputWithToggle,
        NoteText
    },
    props: {
        jsonData: Object
    },
    emits: ['update:enteredWord', 'update:selectedAnswer'],
    methods: {
        updateEnteredWord(index, value) {
            this.$emit('update:enteredWord', index, value);
        },
        updateSelectedAnswer(index, value) {
            this.$emit('update:selectedAnswer', index, value);
        }
    }
};
</script>

<style scoped>
.text-input-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 10px;
    column-gap: 20px;
}

@media (max-width: 1024px) {
    .text-input-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .text-input-grid {
        grid-template-columns: 1fr;
    }
}

.input-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.input-number {
    margin-right: 5px;
    font-weight: bold;
    line-height: 1.5;
}

.note {
    margin-left: 25px;
    margin-bottom: 30px;
}
</style>
