import { openDB } from 'idb';

const dbPromise = openDB('my-database', 1, {
  upgrade(db) {
    db.createObjectStore('dataStore', {
      keyPath: 'id',
    });
  },
});

const serializeData = (data) => {
  return {
    id: data.id,
    data: JSON.parse(JSON.stringify(data.data)),
  };
};

export const saveData = async (data) => {
  const db = await dbPromise;
  const serializableData = serializeData(data)
  await db.put('dataStore', serializableData);
};

export const getData = async (id) => {
  const db = await dbPromise;
  return await db.get('dataStore', id);
};

export const getAllData = async () => {
  const db = await dbPromise;
  return await db.getAll('dataStore');
};

export const deleteData = async (id) => {
  const db = await dbPromise;
  await db.delete('dataStore', id);
};
