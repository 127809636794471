<template>
    <BaseLayout :heading="'Trail Making B'" />

    <div class="instructions"> 
        <div class="header">Practice B</div>
        <div class="line">This time I want you to do something different. I want you to switch or alternate between connecting a number with a letter. Press the continue button. So, as you can see on your screen, you will draw a line from 1 to A, then A to 2, then 2 to B, then B to 3, and so on ending with D. So it’s number, letter, number, letter, number, letter and so on. Press the continue button to practice working as quickly as you can without making an error.</div>
        <div class="line">(Wait for them to finish).</div>
        <div class="last-line"><b>Now press the continue button.</b></div>

        <div class="header">Task B</div>
        <div class="line">On the next screen there will be more numbers and more letters. So again, you’re going to alternate between numbers and letters, 1 to A, A to 2, 2 to B, and so on until you reach 13. I need you to work as quickly as you can but without making an error. Press the continue button to begin.</div>
        <div class="line">(Wait for them to finish).</div>
        <div class="line"><b>Now press the continue button.</b></div>
    </div>

    <NotesSection 
      :administeringNotes="jsonData ? jsonData.administeringNotes : ''" 
      :scoringNotes="jsonData ? jsonData.scoringNotes : ''" 
      ref="notesSection" />

    <TextButton buttonText="Submit" :dark="true" type="submit" @click="submitForm" />
    
    <NotificationPopup 
      :message="notificationMessage" 
      :visible="notificationVisible"
    />

    <ConfirmationPopup
      :visible="showConfirmPopup"
      @confirm="handleConfirmLeave"
      @noSave="handleNoSaveLeave"
      @cancel="showConfirmPopup = false"
    />
</template>

<script>
import BaseLayout from '../BaseLayout.vue';
import NotesSection from '../../components/NotesSection.vue';
import TaskController from '../../controllers/TaskController.js';
import ServerCodeModel from '../../models/ServerCodeModel';  
import TextButton from '@/components/TextButton.vue';
import NotificationPopup from '@/components/NotificationPopup.vue';
import ConfirmationPopup from '@/components/ConfirmationPopup.vue';

export default {
  components: {
      BaseLayout,
      NotesSection,
      TextButton,
      NotificationPopup,
      ConfirmationPopup
  },
  data() {
    return {
      controller: null,
      jsonData: null,
      notificationMessage: '',
      notificationVisible: false,
      showConfirmPopup: false,
      nextRoute: null,
      forceNavigate: false
    };
  },
  computed: {
    isAdministering() {
        return this.$route.query.selection === 'administer';
    }
  },
  async mounted() {
    const serverCodeModel = new ServerCodeModel();
    serverCodeModel.logType = 'InterviewerTrailMakingBLog';

    this.controller = new TaskController(serverCodeModel.logType, 'trailMakingBData', serverCodeModel);

    await this.loadData();
  },
  methods: {
    async loadData() {
      await this.controller.loadData();
      this.jsonData = this.controller.model.data;
    },
    async submitForm() {
      this.notificationMessage = 'Saving...';
      this.notificationVisible = true;

      if(this.jsonData.timeCreated === '') {
        this.jsonData.timeCreated = new Date().toUTCString();
      }
      this.jsonData.timeLastModified = new Date().toUTCString();

      const notesSection = this.$refs.notesSection;

      if (notesSection) {
        const administeringNotes = notesSection.getAdministeringNotes();
        const scoringNotes = notesSection.getScoringNotes();

      if (this.jsonData) {
        this.jsonData.administeringNotes = administeringNotes || '';
        this.jsonData.scoringNotes = scoringNotes || '';
      }

        try {
          await this.controller.submitForm();
          this.notificationMessage = 'Notes Saved Successfully!';
        } catch (error) {
          this.notificationMessage = 'Saving notes failed. Please try again.';
          console.error('Error submitting form:', error);
        }
        finally {
          this.resetNotification();
        }
      } else {
        console.log('notesSection is undefined');
      }
    },
    resetNotification() {
      setTimeout(() => {
        this.notificationVisible = false;
      }, 3000);
    },
    async handleConfirmLeave() {
      this.showConfirmPopup = false;
      await this.submitForm();
      this.$router.push(this.nextRoute);
    },
    handleNoSaveLeave() {
      this.showConfirmPopup = false;
      this.forceNavigate = true;
      this.$router.push(this.nextRoute);
    }
  },
  beforeRouteLeave(to, from, next) {
    const notesSection = this.$refs.notesSection;
    const currentAdministeringNotes = notesSection.getAdministeringNotes();
    const currentScoringNotes = notesSection.getScoringNotes();

    if (this.forceNavigate) {
      this.forceNavigate = false;
      next();
      return;
    }

    if ( 
      (this.isAdministering && currentAdministeringNotes !== this.jsonData.administeringNotes) || 
      (!this.isAdministering && currentScoringNotes !== this.jsonData.scoringNotes) 
    ) {
      this.showConfirmPopup = true;
      this.nextRoute = to.fullPath;
      next(false);
    } else {
      this.controller.model.saveData(this.jsonData);
      next();
    }
  }
};
</script>

<style scoped>
.instructions {
    font-size: 18px;
}
.header {
    font-weight: bold;
    text-decoration: underline;
}
.line {
    margin-bottom: 20px;
}
.last-line {
    margin-bottom: 40px;
}
</style>