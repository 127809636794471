<template>
    <div>
        <p v-if='show'>TASK: {{ task }}</p>
    </div>
</template>


<script>
export default {
    
    props: ['task'],
    data() {
        return {
            show: true
        };
    },
    
};
</script>

<style>
    

</style>