<template>
  <div>
    <div v-if="!showExportSelector">
      <div class="content-box">
        <div class="prompt">
          <div v-if="!userSelection">
            <div class="heading">ACT Interviewer App Home Page</div>
            <p class="question">Do you want to administer or score?</p>
            <div class="button-container">
              <TextButton
                buttonText="Administer"
                :dark="true"
                @click="setSelection('administer')"
              />
              <TextButton
                buttonText="Score"
                :dark="true"
                @click="setSelection('score')"
              />
            </div>
            <div class="export-button-container">
              <TextButton
                class="export-button"
                buttonText="Export Data"
                :dark="true"
                @click="showExportSelector = true"
              />
            </div>
          </div>

          <div v-if="userSelection && !loading">
            <TextButton
              buttonText="Back"
              :dark="true"
              @click="resetSelection"
              class="back-button"
            />
            <div class="next-question">
              <p class="selection-title">{{ titleText }}</p>
              <div class="input-container">
                <label for="subjectIDSelect" class="subject-label">Select Subject ID:</label>
                <select 
                  id="subjectIDSelect"
                  v-model="selectedSubjectIDOption"
                  class="subject-select"
                  @change="handleSubjectIDSelection"
                >
                  <option value="">Select an ID...</option>
                  <option v-for="id in testSubjectIDs" :key="id" :value="id">
                    {{ id === 'custom' ? 'Custom Subject ID' : id }}
                  </option>
                </select>

                <div v-if="showCustomInput" class="custom-input-container">
                  <label for="customSubjectID" class="subject-label">Enter Custom Subject ID:</label>
                  <input 
                    id="customSubjectID"
                    v-model="subjectID" 
                    type="text" 
                    placeholder="Enter Custom Subject ID" 
                    class="subject-input"
                  />
                </div>
                <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
              </div>
              <TextButton
                :buttonText="startButtonText"
                :dark="true"
                @click="startProcess"
                class="start-button"
              />
            </div>
          </div>

          <div v-if="loading && !errors" class="loading-container">
            <div class="loading-main">Loading...</div>
            <div class="loading-sub">Please wait, while we get things ready</div>
            <div class="dots">
              <div class="dot" v-for="n in 5" :key="n" :style="{ animationDelay: `${n * 0.2}s` }"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ExportSubjectSelector
      v-else
      @close="showExportSelector = false"
      @export="handleExport"
    />
  </div>
</template>

<script>
import TextButton from '../components/TextButton.vue';
import ServerCodeModel from '../models/ServerCodeModel.js'
import ExportSubjectSelector from '../components/ExportSubjectSelector.vue'
import { exportSelectedSubjects } from '../utils/export'

export default {
  components: {
    TextButton,
    ExportSubjectSelector
  },
  data() {
    return {
      userSelection: null,
      subjectID: '',
      errorMessage: '',
      serverCodeModel: new ServerCodeModel(),
      loading: false,
      errors: false,
      ls: localStorage,
      testSubjectIDs: [],
      selectedSubjectIDOption: '',
      showCustomInput: false,
      showExportSelector: false
    };
  },
  created() {
    this.testSubjectIDs.push('custom');
    for (let i = 100; i <= 199; i++) {
      this.testSubjectIDs.push(`ACTI${i}`);
    }
  },
  computed: {
    startButtonText() {
      return this.userSelection === 'administer' ? 'Start Administering' : 'Start Scoring';
    },
    titleText() {
      return this.userSelection === 'administer' ? 'Administer' : 'Scoring';
    },
  },
  methods: {
    setSelection(selection) {
      this.userSelection = selection;
      this.errorMessage = '';
      this.$emit('selection-changed', selection);
      console.log("User selected:", this.userSelection);
    },
    resetSelection() {
      this.userSelection = null;
      this.subjectID = '';
      this.errorMessage = '';
      console.log("User reset selection");
    },
    startProcess() {
      if (!this.selectedSubjectIDOption) {
        this.errorMessage = "Please select a Subject ID";
        return;
      }
      
      if (this.selectedSubjectIDOption === 'custom' && (!this.subjectID.trim() || !/^[a-zA-Z0-9]+$/.test(this.subjectID))) {
        this.errorMessage = "Please enter a valid Subject ID";
        return;
      }

      this.serverCodeModel.clearData();
      if ('administer' == this.userSelection) { 
        this.startAdminister(); 
        return; 
      }
      this.startScoring();
    },

    async startAdminister() {
      this.loading = true
      await this.serverCodeModel.loadForAdminister(this.subjectID)
      if (this.serverCodeModel.loadForAdministerResult.ok) {
        // yay
        this.gotoFirstTask()
        return
      } 
      
      // aww
      this.errors = true
      this.errorMessage = "Darn, it didn't work"


    },

    async startScoring() {
      this.loading = true
      await this.serverCodeModel.loadForScoring(this.subjectID)
      if (this.serverCodeModel.loadForScoringResult.ok) {
        // yay
        this.gotoFirstTask()
        return
      }
      
      // aww
      this.errors = true
      this.errorMessage = "Subject ID not found"
      this.loading = false
    },

    gotoFirstTask() {
      this.$router.push({
          path: '/introduction',
          query: { selection: this.userSelection }
        });
    },

    goBackFromError(){
      this.loading = false
      this.errors = false
      this.errorMessage = ''
    },

    handleSubjectIDSelection() {
      if (this.selectedSubjectIDOption === 'custom') {
        this.showCustomInput = true;
        this.subjectID = '';
      } else {
        this.showCustomInput = false;
        this.subjectID = this.selectedSubjectIDOption;
      }
      this.errorMessage = '';
    },

    async handleExport(selectedSubjects) {
      try {
        await exportSelectedSubjects(selectedSubjects);
        this.showExportSelector = false;
      } catch (error) {
        console.error('Export failed:', error);
      }
    }

  }
};
</script>

<style scoped>
.heading {
  font-weight: normal;
  font-size: 30px;    
  margin-bottom: 50px;
  color: #237091;
  text-align: center;
}

.content-box {
  background-color: #F4F4F4;
  padding: 40px;
  border-radius: 6px;
  border: 1px solid #a0a0a0;
  max-width: 600px;
  height: 450px;
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
}

.prompt {
  text-align: center;
}

.question {
  font-size: 20px;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
}

.button-container > * {
  width: 200px;
  padding: 12px 0;
  text-align: center;
}

.next-question {
  max-width: 300px;
  margin: 0 auto;
  position: relative;
}

.selection-title {
  font-size: 30px;
  color: #3986A8;
  margin-bottom: 30px;
}

.input-container {
  margin-bottom: 20px;
  text-align: left;
}

.subject-label {
  display: block;
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}

.subject-input {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid #3986A8;
}

.subject-input::placeholder {
  font-size: 14px;
  color: #aaa;
}

.start-button {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 0;
  display: block;
  margin: 0 auto 0;
  text-align: center;
}

.back-button {
  position: absolute;
  top: 30px;
  left: 30px;
}

.error-message {
  color: #FF0000;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.loading-main {
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 24px;
}

.loading-sub {
  margin-bottom: 30px;
  font-size: 20px;
}

.dots {
  display: flex;
  gap: 10px;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: grey;
  border: 1px solid #333;
  animation: fade 1s infinite alternate;
}

@keyframes fade {
  0% {
    background-color: grey;
  }
  100% {
    background-color: white;
  }
}

.subject-select {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 2px solid #3986A8;
  background-color: white;
  margin-bottom: 10px;
}

.custom-input-container {
  margin-top: 15px;
}

.subject-select:focus {
  outline: none;
  border-color: #237091;
}

.export-button-container {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.export-button {
  width: 200px;
}
</style>
