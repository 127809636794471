import TaskRecord from './TaskRecord';

class ContructionalPraxisTaskRecord extends TaskRecord {

    constructor() {
        super()
        this.taskType = 'ConstructionalPraxis'
        this.drawings = null
    }

    parseLogExtra(logJson) {
        if (!logJson) return
        this.drawings = logJson['Drawings']
    }

    //--
}

export default ContructionalPraxisTaskRecord;