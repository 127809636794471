import TaskRecord from './TaskRecord';

class VerbalFluencyTaskRecord extends TaskRecord {

    constructor() {
        super()
        this.taskType = 'VerbalFluency'
    }

    parseLogExtra(logJson) {
        if (!logJson) return
        if (!logJson['Summary']) return
        this.fileName = logJson['Summary']['RecordingFilename']
    }

    //--
}

export default VerbalFluencyTaskRecord;