<template>
    <div v-if="visible" class="confirmation-dialog-overlay">
        <div class="confirmation-dialog">
            <p>{{ message }}</p>
            <div class="button-container">
                <button @click="confirm">Save and Continue</button>
                <button @click="noSave">Don't Save, Continue</button>
                <button @click="cancel">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            default: 'You have unsaved changes. Do you want to save?'
        },
        visible: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        confirm() {
            this.$emit('confirm');
        },
        noSave() {
            this.$emit('noSave');
        },
        cancel() {
            this.$emit('cancel');
        }
    }
    };
</script>

<style scoped>
.confirmation-dialog-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    color: #000;
    border: 4px solid #3986A8;
    background-color: #fff;
    z-index: 1000;
    text-align: center;
    font-size: 20px;
    max-width: 80%;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.confirmation-dialog {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
}

.button-container {
    margin-top: 20px;
}

button {
    margin: 5px 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}
</style>
