<template>
    <div class="instructions">
        <div class="line">Please Press the continue button to advance to the next screen. On this screen, I want you to draw the face of a clock with all the numbers on it and make it large. Do not Press the submit button until you are instructed.</div>
        <div class="line">Now, draw the hands, pointing at 20 to 4.</div>
        <div class="line">(Wait for them to finish drawing)</div>
        <div>That is fine. <b>Please Press the submit button.</b></div>
    </div>
</template>

<style scoped>
.instructions {
    font-size: 18px;
}
.line {
    margin-bottom: 20px;
}
</style>