import TaskRecord from './TaskRecord';

class WaisRTaskRecord extends TaskRecord {

    constructor() {
        super()
        this.taskType = 'WAIS_R'
    }

    parseLogExtra(logJson) {
        if (!logJson) return
        this.fileName = logJson['RecordingName']
        this.stageLabel = logJson['Parameters']['StageLabel']
    }

    //--
}

export default WaisRTaskRecord;