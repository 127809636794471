<template>
    <h3>Internal Logs Scoring</h3>
    <LogSelector v-if='controller.isSelectingLogFile' :controller='controller'/>
    <LogTaskList v-if='controller.hasLog' :controller='controller' :tasks='controller.model.tasks'/>
    <LogTask v-if='controller.hasTask' :controller='controller' :task='controller.task'/>
</template>

<script>
import LogSelector from '@/tmp_log_scoring/components/LogSelector.vue';
import LogTaskList from '@/tmp_log_scoring/components/LogTaskList.vue';
import LogTask from '@/tmp_log_scoring/components/LogTask.vue';
import LogController from '@/tmp_log_scoring/controller/LogController';

export default {
  components: {
    LogSelector,
    LogTaskList,
    LogTask
  },
  data() {
    return {
      controller: new LogController(),
      jsonData: null,
      showAudio: false,
      showImage: false
    }
  }
}
</script>

<style scoped>
.instructions {
    font-size: 20px;
    line-height: 1.5;
}
</style>