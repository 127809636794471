<template>
    <div class="login-container">
        <h1 class="heading">Welcome</h1>
        <form @submit.prevent="handleLogin">
            <div class="form-group">
                <label for="username" class="label">Username</label>
                <input
                    type="text"
                    id="username"
                    v-model="username"
                    placeholder="Enter username"
                    class="input-container"
                />
            </div>

            <div class="form-group">
                <label for="password" class="label">Password</label>
                <input
                    type="password"
                    id="password"
                    v-model="password"
                    placeholder="Enter password"
                    class="input-container"
                />
            </div>

            <div v-if="errorMessage" class="error-message">
                {{ errorMessage }}
            </div>

            <TextButton
                :buttonText='"Login"'
                :dark="true"
                @click="handleLogin"
                class="login-button"
                type="submit"
            />
        </form>
    </div>
</template>

<script>
import TextButton from "../components/TextButton.vue";

export default {
    components: {
        TextButton,
    },
    data() {
        return {
            username: "",
            password: "",
            errorMessage: "",
        };
    },
    methods: {
        handleLogin() {
            const validUsername = "Scorer";
            const validPassword = "Internal";

            if (this.username === validUsername && this.password === validPassword) {
                sessionStorage.setItem("isLoggedIn", "true");
                this.$router.push("/scoring/internal");
            } else {
                this.errorMessage = "Incorrect username or password";
            }
        },
    },
};
</script>

<style scoped>
.login-container {
    max-width: 600px;
    height: 450px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 40px;
    background-color: #F4F4F4;
    border: 1px solid #a0a0a0;
    border-radius: 6px;
    position: relative;
}

.heading {
    font-weight: normal;
    font-size: 30px;
    margin-bottom: 25px;
    color: #237091;
    text-align: center;
}

.login-button {
    padding: 12px;
    margin: 0 auto 0;
    text-align: center;
    padding: 0 30px;
}

.form-group {
    padding-bottom: 25px;
    text-align: left;
    max-width: 300px;
    margin: 0 auto;
}

.input-container {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 8px;
    border: 2px solid #3986a8;
    box-sizing: border-box;
}

.label {
    display: block;
    font-size: 18px;
    color: #333;
    margin-bottom: 8px;
}

.error-message {
    color: red;
    margin-bottom: 25px;
    text-align: center;
}
</style>
