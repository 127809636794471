<template>

    <div>
        <p><strong>GENERIC</strong></p>
        <p>
            <strong>Stage Label</strong>: {{  task.stageLabel }}<br>
            <strong>File Name</strong>: {{  task.fileName }}
        </p>
        <TaskConfig :task='task'/>
    </div>

</template>


<script>
import TaskConfig from '@/tmp_log_scoring/components/TaskConfig.vue'
export default {
    props: ['controller', 'task'],
    components: {
        TaskConfig,
    },
    
};
</script>

<style>
    

</style>