<template>
    <div class="input-field">
        <input
            type="text"
            :placeholder="input.name === 'dob' ? 'MM/DD/YYYY' : 'Type Here'"
            :id="input.name"
            v-model="textInput"
            @input="handleTextInput"
        />
        <TextButton
            v-if="input.name === 'startTime'"
            class="start-time"
            type="button"
            buttonText="Set Current Time"
            @click="setCurrentTime"
        />
        <span v-if="textInput" :class="{'error-message': isError, 'success-message': isSuccess}">
            {{ message }}
        </span>
    </div>
</template>

<script>
import { defineComponent, ref, toRefs, onMounted, watch } from 'vue';
import TextButton from '../TextButton.vue';
import CASIController from '../../controllers/CASIController';
import casiState from '../../store/casiState';

const casiController = new CASIController();

export default defineComponent({
    props: {
        input: {
            type: Object,
            required: true
        },
        modelValue: {
            type: String,
            default: ''
        }
    },
    components: { 
        TextButton 
    },
    setup(props, { emit }) {
        const { input } = toRefs(props);
        const valueNode = input._object.input['value'];
        let currValue = '';
        if (valueNode != null) {
            currValue = valueNode['enteredText'];
        }
        const isValidDob = ref(true);
        const isMatchingYear = ref(false);
        const isMatchingMonth = ref(false);
        const isMatchingDay = ref(false);
        const isMatchingAge = ref(false);
        const isMatchingYear2 = ref(false);
        const isMatchingMonth2 = ref(false);
        const isMatchingDay2 = ref(false);

        const textInput = ref('');
        if (input.value.name === 'startTime' && input.value.value.enteredText) {
            const date = new Date(input.value.value.enteredText);
            textInput.value = date.toLocaleTimeString();
        } else {
            textInput.value = currValue;
        }
        
        let debounceTimeout = null;

        const message = ref('');
        const isError = ref(false);
        const isSuccess = ref(false);

        function validateInputs() {
            message.value = '';
            isError.value = false;
            isSuccess.value = false;

            if (props.input.name === 'birthYear') {
                isMatchingYear.value = casiController.validateYear(textInput.value);
                casiState.isMatchingYear = isMatchingYear.value;
                setMessage(isMatchingYear.value, 'The inputted year matches the participant\'s birth year.', 'The inputted year does not match the participant\'s birth year.');
            }
            if (props.input.name === 'birthMonth') {
                isMatchingMonth.value = casiController.validateMonth(textInput.value);
                casiState.isMatchingMonth = isMatchingMonth.value;
                setMessage(isMatchingMonth.value, 'The inputted month matches the participant\'s birth month.', 'The inputted month does not match the participant\'s birth month.');
            }
            if (props.input.name === 'birthDay') {
                isMatchingDay.value = casiController.validateDay(textInput.value);
                casiState.isMatchingDay = isMatchingDay.value;
                setMessage(isMatchingDay.value, 'The inputted day matches the participant\'s birth day.', 'The inputted day does not match the participant\'s birth day.');
            }
            if (props.input.name === 'age') {
                isMatchingAge.value = casiController.validateAge(textInput.value);
                setMessage(isMatchingAge.value, 'The inputted age matches the participant\'s age.', 'The inputted age does not match the participant\'s age.');
            }
            if (props.input.name === 'birthYear2') {
                isMatchingYear2.value = casiController.validateYear(textInput.value);
                setMessage(isMatchingYear2.value, 'The inputted year matches the participant\'s birth year.', 'The inputted year does not match the participant\'s birth year.');
            }
            if (props.input.name === 'birthMonth2') {
                isMatchingMonth2.value = casiController.validateMonth(textInput.value);
                setMessage(isMatchingMonth2.value, 'The inputted month matches the participant\'s birth month.', 'The inputted month does not match the participant\'s birth month.');
            }
            if (props.input.name === 'birthDay2') {
                isMatchingDay2.value = casiController.validateDay(textInput.value);
                setMessage(isMatchingDay2.value, 'The inputted day matches the participant\'s birth day.', 'The inputted day does not match the participant\'s birth day.');
            }
        }

        function setMessage(isMatch, successMessage, errorMessage) {
            if (isMatch) {
                message.value = successMessage;
                isSuccess.value = true;
            } else {
                message.value = errorMessage;
                isError.value = true;
            }
        }

        onMounted(() => {
            validateInputs();
        });

        watch(textInput, () => {
            validateInputs();
        });

        function handleTextInput(event) {
            const value = event.target.value;
            textInput.value = value;
            emit('input-changed');

            if (debounceTimeout) {
                clearTimeout(debounceTimeout);
            }

            if (props.input.name === 'dob') {
                isValidDob.value = validateDob(value);
                if (isValidDob.value) {
                    const [month, day, year] = value.split('/');
                    const monthNum = convertMonthNameToNumber(month.trim());
                    casiController.setDob(monthNum, day, year);
                    console.log(`Extracted - Month: ${monthNum}, Day: ${day}, Year: ${year}`);
                }
            }

            if(props.input.name === 'ageQ') {
                casiController.setAge(value);
            }

            debounceTimeout = setTimeout(() => {
                console.log(`Text Input - Name: ${input.value.name}, Value: ${value}`);
                input.value.value.enteredText = value;
                console.log("Updated JSON:", input.value);
            }, 1000);
        }

        function convertMonthNameToNumber(monthName) {
            const months = {
                'january': 1, 'february': 2, 'march': 3, 'april': 4, 'may': 5, 'june': 6,
                'july': 7, 'august': 8, 'september': 9, 'october': 10, 'november': 11, 'december': 12
            };

            const monthLower = monthName.toLowerCase();
            const monthNum = months[monthLower];
            
            return monthNum || parseInt(monthName, 10);
        }

        function setCurrentTime() {
            const now = new Date();
            const localTimeString = now.toLocaleTimeString();
            const utcString = now.toUTCString();
            textInput.value = localTimeString;
            input.value.value.enteredText = utcString;
        }

        function validateDob(value) {
            const dobRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
            return dobRegex.test(value);
        }

        return {
            textInput,
            handleTextInput,
            isValidDob,
            isMatchingYear,
            isMatchingMonth,
            isMatchingDay,
            isMatchingAge,
            setCurrentTime,
            isMatchingYear2,
            isMatchingMonth2,
            isMatchingDay2,
            message,
            isError,
            isSuccess
        };
    }
});
</script>

<style scoped>
.start-time {
    margin-top: 10px;
    font-size: 14px;
    width: 40%;
}
.input-field input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 90%;
    max-width: 400px;
    margin-top: 2px;
}
.input-field {
    width: 90%;
    max-width: 400px;
    margin-top: 2px;
}
input::placeholder {
    opacity: 0.5;
    font-size: 12px;
}
.error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    display: block;
}
.success-message {
    color: green;
    font-size: 12px;
    margin-top: 5px;
    display: block;
}
</style>
