<template>
    <div class="container">
        <div class="line-input">
            <textarea
                :value="text"
                @input="handleInput"
                class="text"
                rows="1"
            ></textarea>
            <FourWayToggle 
                :modelValue="selectedOption"
                @update:modelValue="$emit('update:selectedOption', $event)"
            />
        </div>
    </div>
</template>

<script>
import FourWayToggle from './FourWayToggle.vue';

export default {
    components: {
        FourWayToggle
    },
    props: {
        text: String,
        selectedOption: String
    },
    emits: ['update:text', 'update:selectedOption'],
    methods: {
        handleInput(event) {
            const textarea = event.target;
            textarea.style.height = 'auto';
            textarea.style.height = textarea.scrollHeight + 'px';

            this.$emit('update:text', event.target.value);

            if (event.target.value && this.selectedOption === null) {
                this.$emit('update:selectedOption', 'c');
            }
        }
    }
};
</script>

<style scoped>
.container {
    width: 100%;
    padding: 10px 10px 10px 5px;
    box-sizing: border-box;
}

.line-input {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 8px 8px 0;
    margin-bottom: 10px;
    cursor: text;
}

.text {
    flex: 1;
    font-family: inherit;
    font-size: 16px;
    padding: 10px;
    border: 1px solid gray;
    background: transparent;
    outline: none;
    box-shadow: none;
    box-sizing: border-box;
    width: 100%;
    margin-right: 10px;
    resize: none;
    overflow: hidden;
}
</style>
