import TaskRecord from './TaskRecord';

class LogicalMemoryTaskRecord extends TaskRecord {

    constructor() {
        super()
        this.taskType = 'LogicalMemory'
    }

    //--
}

export default LogicalMemoryTaskRecord;