import filesList from '../data/logsFileList.json';

class Logs {

    static logsURLBase = 'https://braingamecenter.s3.us-east-2.amazonaws.com/ACTH_2021_05_scoring/'

    constructor() {
        this.filesList = null
    }
  
    async loadFilesList() {
        this.filesList = filesList 
    }

    //--
}
  
export default Logs;